/**
 * Check if two objects are functionally equal (have the same properties)
 * @param {Object} a
 * @param {Object} b
 * @param {string[]} [ignoredKeys] List of object keys to ignore when comparing (using dot notation for nested keys)
 * @param {Boolean} [reapplyIgnoredToChildren=false] Ignore the same keys in child objects?
 * @returns {Boolean}
 */
export function isObjectEqual (a, b, ignoredKeys = [], reapplyIgnoredToChildren) {
	try {
		if (a === b) return true;
		if (typeof a !== "object" || typeof b !== "object" || !a || !b) return false;
		let aKeys = Object.keys(a).filter(k => !ignoredKeys.includes(k));
		let bKeys = Object.keys(b).filter(k => !ignoredKeys.includes(k));

		if (aKeys.length !== bKeys.length) return false;

		for (let k of aKeys) {
			if (a[k] !== b[k]) {
				if (typeof a[k] === "object") {
					let nextIgnored = [];
					if (reapplyIgnoredToChildren) nextIgnored.push(...ignoredKeys);
					// pass nested ignored key list
					nextIgnored.push(...ignoredKeys.reduce((accu, current) => {
						let split = current.split(".");
						if (split.shift() !== k) return accu;
						accu.push(split.join("."));
						return accu;
					}, []));
					if (!isObjectEqual(a[k], b[k], nextIgnored, reapplyIgnoredToChildren)) return false;
				} else {
					return false;
				}
			}
		}

		return true;
	} catch (e) {
		return false;
	}
}
