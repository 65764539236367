import React from "react";
import { generateInputGroup } from "../tools/generateInputGroup";
import { getModelFields } from "../tools/getModelFields";

/**
 * @param {Object} props
 * @param {string[]} props.fieldPath
 * @param {FieldDefinition} props.definition
 * @param {function} props.onChange
 * @param {*} props.state
 * @param {MutableRefObject<boolean>} [props.saved]
 */
export default function JsonEditor (props) {
	/*return Object.entries(props.definition.schema).flatMap(([attrName, attr], index) => {
		return [
			<div style={{height: '50px',display:'block'}}>yes</div>,
			...generateInputGroup()
		];
	});*/
	const onChange = ({ fieldPath, value }) => {
		props.onChange({
			...props.state,
			[fieldPath]: value // FIXME: this is not recursive! use setIn instead
		});
	};
	return getModelFields(props.definition.schema).map((field, index) =>
		generateInputGroup(field, index, (...a) => onChange(...a), props.state, props.saved)
	);
}
