import React, { useReducer, useCallback, useRef } from 'react';
import { fromJS, Map as ImmuMap } from 'immutable';
import { useSelector, useDispatch } from 'react-redux';

import { CrudoState, actions as crudoActions } from 'crudservice-client';

import useRouter from '../useRouter';
import Loader from "./Loader";
import BackButton from './BackButton'
import ArrayEditor from "./ArrayEditor";

import useCrudoData from "../useCrudoData";
import { submitButtonStyle } from '../Theme/styles';
import { processInitialState } from './EditForm/tools';
import { formReducer } from './EditForm/reducer';
import styled from "styled-components";

import { getModelFields } from '../tools/getModelFields';
import { generateInputGroup } from '../tools/generateInputGroup';
import { getDeepProperty } from '../tools/getDeepProperty';

// hotfix: getting ref models causes infinite loop
const gettingRefModels= {};


/**
 * Form used to edit/create documents.
 * @param {Object} props
 * @param {string} props.modelName
 * @param {(string|null)} props.documentIdentifier
 */
export default function ClassicEditForm(props) {
	const dispatch = useDispatch();
	const saved = useRef(false);
	const { history, match } = useRouter();
	const { fieldsGenerator = getModelFields } = props;

	const creating = props.documentIdentifier === null;
	const {
		/** @type {boolean} */ dataReady,
		/** @type {boolean} */ refsReady,
		/** @type {boolean} */ translatable,
		/** @type {Object.<string, Object>} */ rawModelSchema,
		/** @type {Object.<string, *>} */ values,
		/** @type {ImmuMap} */ valuesImmu,
	} = useSelector(state => {

		const rawModelSchema = state.crudo.getIn([props.modelName, 'definition', 'data'], {});

		let dataReady = true;
		if (!creating) { // get current doc state
			/*[CrudoState.OK, true ].includes(state.crudo.getIn([ 'models', props.modelName, 'data', props.documentIdentifier, 'crudoState' ],
				state.crudo.hasIn([ 'models', props.modelName, 'data', props.documentIdentifier, '_id' ])
				// the default value expresses the fact that data was loaded in a different way, eg. a bulk request. _id should otherwise be undefined
			)),*/
			dataReady = state.crudo.getIn([props.modelName, 'data', props.documentIdentifier, 'crudoState'], CrudoState.INITIAL) === CrudoState.OK;
		}  // else ok, no data required

		const valuesImmu = creating ? /* is empty */ ImmuMap() : /* pull doc from redux store */
			state.crudo.getIn([props.modelName, 'data', props.documentIdentifier], ImmuMap());

		let refsReady = true;
		const refs = state.crudo.getIn([props.modelName, 'refs'], []);
		if (refs.length) {
			// model has some refs, get data that'll be used for their autocomplete

			// get list of (distinct) required models
			let models = new Set();
			for (const refAttr of refs) {
				models.add(rawModelSchema[refAttr].refModel);
			}

			// query the store for each model's string representations
			for (const refModel of models) {
				const listState = state.crudo.getIn([`_stringrep_${refModel}`, 'crudoState'], CrudoState.INITIAL);
				if (listState !== CrudoState.OK) {
					refsReady = false;

					// FIXME: this no longer feels redux-y, more like dispatching actions from random place
					if (listState === CrudoState.INITIAL && !gettingRefModels[refModel]) {
						console.log("classic edit form getting : "+refModel);
						//FIXME: (@holecek) this dispatch causes infinite loop (to simulate the problem, just refresh this page)
						// variable "gettingRefModels" is just hotfix, which should be removed after fix
						gettingRefModels[refModel] = true;
						dispatch({
							type: crudoActions.GET_STRING_REPRESENTATIONS,
							modelName: refModel,
						});
					}
				}
			}
		}

		return {
			rawModelSchema,
			translatable: state.crudo.getIn([props.modelName, 'translatable'], false),
			dataReady,
			valuesImmu,
			values: valuesImmu.toJS(),
			refsReady,
		};
	});

	const ready = dataReady && refsReady;


	const { _id, id, _meta, ...realValues } = values;


	// language mutations. TODO, this is just a PoC
	const langsImmu = useCrudoData(['_crudoConfig', 'data', 'languages'], fromJS([null]));
	const langs = langsImmu ? langsImmu.toJS() : [];


	let initialState = processInitialState(valuesImmu, rawModelSchema, translatable, langs);
	const [localState, localDispatch] = useReducer(formReducer, initialState);



	/**
	 * @type {function}
	 * Check if form state is dirty and if so, warn the user before navigating away
	 * @param {Event} [ev] If called during window.beforeunload, the unload event
	 * @returns {(string|void)} If not called from window.beforeunload, a message for blocking navigation
	 */
	const beforeFormDespawn = React.useCallback(function (ev) {
		if (localState._dirty) {
			const str = 'You have unsaved changes. Are you sure you want to leave and discard them?';
			if (ev) {
				// browser closing or navigating away from aui. can't do much but ask them to stop
				ev.preventDefault();
				ev.returnValue = str;
				// (the string isn't shown but has to be set, see MDN to know why)
			} else if (!saved.current) {
				// navigating away within aui
				return str;
			}
		}
	}, [localState, saved]);

	React.useEffect(() => {
		const unblock = history.block(() => {
			return beforeFormDespawn();
		});
		window.addEventListener('beforeunload', beforeFormDespawn);
		return () => {
			window.removeEventListener('beforeunload', beforeFormDespawn);
			unblock();
		};
	}, [beforeFormDespawn, history]);

	const rd = useDispatch()
	const reduxDispatch = useCallback(
		(...args) => rd(...args),
		[rd]
	)


	// if editing and document not loaded, don't display the form just yet
	if (!ready) return <Loader visible icon="spinner" title="LOADER" />;

	let formFields = [];
	let modelFields = fieldsGenerator(rawModelSchema, langs);

	//console.log(modelFields);

	formFields = modelFields.map((field, fIdx) => {
		return generateInputGroup(field, fIdx, localDispatch, localState, saved);

	})


	/**
	 * Verify that all the form fields are validated properly and then 'submit the form' (= dispatch save to redux)
	 * @param {Event} e Submit event, will be canceled.
	 * @returns {void}
	 */
	function verifyAndSave(e) {
		e.preventDefault();

		// if valid, dispatch to app

		// TODO: these should not be put in the reducer initial state in the first place...
		const formState = { ...localState };
		delete formState._id;
		delete formState._meta;

		saved.current = true;

		reduxDispatch({
			type: 'ADMINAPP_SAVE_DOCUMENT',
			form: formState,
			modelName: props.modelName,
			creating,
			translatable,
			languages: langs,
			documentIdentifier: props.documentIdentifier,
			rawModelSchema,
			// history,
			// projectId: match.params.projectId,
			onSuccess: () => {
				history.push(`/project/${match.params.projectId}/model/${props.modelName}/list`);
			},
			onFailure: () => {
				saved.current = false;
			},
		});
	}

	let formStateEntries = Object.entries(localState);

	if (translatable) {
		// change { en: { x: {}... }, cs: { x: {}... }} to { en_x: {}..., cs_x: {}... } to conform with the standard state
		let actualEntries = [];
		formStateEntries.forEach(([lang, langFields]) => {
			if (typeof langFields !== 'object' || langFields === null)
				return;

			actualEntries.push(...Object.entries(langFields).map(([fieldName, field]) =>
				([`${lang}_${fieldName}`, field])
			));
		});
		formStateEntries = actualEntries;
	}


	return <form onSubmit={verifyAndSave}>
		<h3>{_id}</h3>
		<BackButton params={match.params} />
		<button key='submit_top' style={submitButtonStyle} type='submit'>Save</button>
		<br />
		{formFields}
		<button key='submit_bottom' style={submitButtonStyle} type='submit'>Save</button>
	</form>;
}
