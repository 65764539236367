import React  from 'react';

import produce from 'immer';
import { getDeepProperty } from '../../tools/getDeepProperty';
import { isObjectEqual } from '../../tools/isObjectEqual';
/**
 * @typedef {Object} ValidationState
 * @property {boolean} valid
 * @property {string} [message]
 */

/**
 * @typedef {Object} changeAction
 * @property {'change'} type
 * @property {*} fieldPath
 * @property {*} value
 * @property {(string|null)} [language] If set, we're dealing with a multilang form
 * @property {ValidationState} [validationState]
 */

/**
 * @typedef {changeAction} changeArrayAction
 * @property {'change-array'} type
 * @property {number} index
 */

/**
 * @typedef {changeAction} changePositionAction
 * @property {'change-position'} type
 * @property {number} index Index of source element in the underlying array
 * @property {number} targetPosition Target of the desired DISPLAY position
 */

/**
 * Internal edit form reducer. Has nothing to do with Redux state!
 * @param state
 * @param {(changeArrayAction|changePositionAction|changeAction)} action
 * @returns Modified state
 */
export function formReducer(state, action) {
	//console.log(action);
	switch (action.type) {
		case 'change':
			return produce(state, draft => {
				if (!state._dirty && !isObjectEqual(getDeepProperty(state, action.fieldPath), action.value))
					draft._dirty = true;
				setIn(draft, action.fieldPath, action.value);
				//setIn(draft, action.fieldPath + '.validationState', action.validationState);
			});
		case 'change-array':
			let arrResult= produce(state, state => {
				state._dirty = true;
				//setIn(state, [...action.fieldPath, 'validationState'], action.validationState)
				setInArray(state, [...action.fieldPath], action.index, action.value);

				// check if adding element and if so, prepare its displayIndex
				let highestUsedIndex = -1;
				let highestUsedPosition = -1;
				Object.entries(state._displayIndices[action.fieldPath]).forEach(([index, pos]) => {
					if (index > highestUsedIndex) highestUsedIndex = parseInt(index);
					if (pos > highestUsedPosition) highestUsedPosition = pos;
				});
				if (action.index > highestUsedIndex) {
					// adding new element
					state._displayIndices[action.fieldPath][highestUsedIndex + 1] = highestUsedPosition + 1;
				}
			});
			return arrResult;
		case 'change-position':
			return produce(state, state => {
				state._dirty = true;

				const p = action.fieldPath;
				const elementBeingMovedIndex = action.index;
				const elementBeingMovedPosition = state._displayIndices[p][elementBeingMovedIndex];
				const targetPosition = action.targetPosition;


				if (targetPosition === -1) {
					state._displayIndices[p][elementBeingMovedIndex] = targetPosition;
					// move all elements that have a higher display pos down by one
					Object.entries(state._displayIndices[p]).forEach(([index, pos]) => {
						if (pos > elementBeingMovedPosition)
							state._displayIndices[p][index]--;
					})
				} else {
					// swap the elements
					const [targetPositionIndex] = Object.entries(state._displayIndices[p]).find(([index, displayPos]) => {
						return displayPos === targetPosition;
					});
					state._displayIndices[p][targetPositionIndex] = elementBeingMovedPosition;
					state._displayIndices[p][elementBeingMovedIndex] = targetPosition;
				}
			});

		default:
			return state;
	}
}


/**
 * Set a nested property
 * @param {Object} src Object to modify
 * @param {string[]} paramPath "Path" to the child property
 * @param {*} value The value to apply
 * @returns {void}
 */
function setIn (src, paramPath, value) {
	let res = src;
	const lastIndex = paramPath.length - 1;
	for (let i = 0; i < lastIndex; i++){
		if (!res[paramPath[i]]) res[paramPath[i]] = {};
		res = res[paramPath[i]];
	}
	res[paramPath[lastIndex]] = value;
}



function setInArray(src, paramPath, index, value) {
	let res = src;
	const lastIndex = paramPath.length - 1;
	for (let i = 0; i < lastIndex; i++){
		if (!res[paramPath[i]]) res[paramPath[i]] = {};
		res = res[paramPath[i]];
	}
	if (!res[paramPath[lastIndex]]){
		res[paramPath[lastIndex]]=[];
	}
	res[paramPath[lastIndex]][index] = value;
}
