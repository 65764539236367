// Library
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper';
// import { replace } from 'react-router-redux';

import  Loader  from "../components/Loader";

// Project
import {
	getSelectIsLoggedIn,
} from './selector'; 

const locationHelper = locationHelperBuilder({});

const UserIsAuthenticatedDefaults = {
	wrapperDisplayName: 'UserIsAuthenticated',
	// Determine if the user is authenticated or not
	authenticatedSelector: (state, props) => getSelectIsLoggedIn(state, props),
	authenticatingSelector: state => state.userState && state.userState.getIn(['loading']),
};

export const UserIsAuthenticatedRedirect = connectedRouterRedirect({
	...UserIsAuthenticatedDefaults,
	// Show loading component while authenticating
	AuthenticatingComponent: Loader,
	// The url to redirect user if token is invalid
	redirectPath: state => (state.userState.getIn(['showLogout'], false) ? '/logout' : '/login'),
});

export const UserIsNotAuthenticatedDefaults = {
	// Want to redirect the user when they are done loading and authenticated
	authenticatedSelector: (state, props) => !getSelectIsLoggedIn(state, props),
	wrapperDisplayName: 'UserIsNotAuthenticated',
};

export const UserIsNotAuthenticatedRedirect = connectedRouterRedirect({
	...UserIsNotAuthenticatedDefaults,
	redirectPath: (state, ownProps) =>
		locationHelper.getRedirectQueryParam(ownProps) || '/',
	allowRedirectBack: false,
});

export const AuthehticateRootDir = connectedRouterRedirect({
	...UserIsAuthenticatedDefaults,
	redirectPath: (state, ownProps) =>
		locationHelper.getRedirectQueryParam(ownProps) || '/gateway',
	allowRedirectBack: false,
});

// Helper function to check authentication inside a component
// export const userIsAuthenticated = connectedAuthWrapper(UserIsAuthenticatedDefaults);
export const UserIsNotAuthenticated = connectedAuthWrapper(UserIsNotAuthenticatedDefaults);
