import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
//const styled = {div: str => props => <div>{props.children}</div>}

const PCDiv = styled.div`
	position: relative;
	padding: 30px 20px;
	background-color: white;
	border: 1px solid #eaeaea;
	border-radius: 4px;
	width: 230px;
	margin-right: 20px;
	text-align: center;
	box-shadow: 0px 0px 5px 0px #e0e0e0;
	.btn-settings {
		opacity: 0;
		transition: opacity 0.1s ease-in;
		color: #2d2d2d;
		font-size: 20px;
		position: absolute;
		right: 15px;
		top: 15px;
	}
	.header {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;
		margin-top: 10px;
		.btn-favourite {
			margin-right: 10px;
		}
		h4 {
			margin: 0px;
			font-size: 20px;
			font-weight: 400;
			color: #593fa1;
		}
	}
	.statistics {
		margin-bottom: 40px;
		display: flex;
		align-items: center;
		position: relative;
		justify-content: center;
		.counter {
			transition: transform 0.1s ease-in, opacity 0.2s ease;
			.number {
				display: block;
				font-weight: 600;
				font-size: 45px;
				color: #5a3fa1;
			}
			.desc {
				font-size: 12px;
				color: gray;
				font-weight: 300;
			}
		}
		.btn-responses {
			transition: opacity 0.1s ease-in;
			position: absolute;
			opacity: 0;
			line-height: 25px;
			top: 10px;
			width: 100%;
			text-align: center;
			color: #2d2d2d;
			font-weight: bold;
			font-size: 12px;
			text-decoration: none;
			i {
				font-size: 38px;
				display: block;
			}
		}
	}
	&:hover {
		.btn-settings {
			opacity: 1;
		}
		.counter {
			opacity: 0;
			transform: translateX(-20px);
		}
		.btn-responses {
			opacity: 1;
		}
	}
	.btn-detail {
		background-color: #fbc135;
		color: #10100c;
		padding: 12px 25px;
		border-radius: 4px;
		font-size: 13px;
		font-weight: 600;
		text-decoration: none;
		margin-top: 30px;
		display: inline-block;
		&:hover {
			background-color: #f1af11;
		}
	}
`;

const DDiv = styled.div`
	h2 {
		font-size: 28px;
	  font-weight: 800;
		margin-left: 15px;
	}
	.projects-list {
		display: flex;
		justify-content: flex-start;
		.btn-create-project {
			border: 1px dashed #eaeaea;
			border-radius: 4px;
			width: 250px;
			text-align: center;
			background-color: #f3f3f3;
			a {
				position: relative;
				display: block;
				width: 100%;
				height: 100%;
				color: #2e2e2e;
				i {
					font-size: 30px;
					position: absolute;			
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);		
				}
			}
		}
	}
`;

const projects = [
	/*{
		id: 1,
		title: 'My project 13',
		responses: 18,
		favorite: false,
		data: [
			{ y: 4000 },
			{ y: 3000 },
			{ y: 2000 },
			{ y: 2780 },
			{ y: 1890 },
			{ y: 2390 },
			{ y: 3490 },
		],
	},
	{
		id: 2,
		title: 'My project 2',
		favorite: true,
		responses: 34,
		data: [
			{ y: 2400 },
			{ y: 1398 },
			{ y: 9800 },
			{ y: 3908 },
			{ y: 4800 },
			{ y: 3800 },
			{ y: 4300 },
		],
	},*/
	{
		id: 0,
		name: 'Crud App',
		/*favorite: true,
		responses: 0,
		data: [],*/
	},
];

function ProjectCard (props) {
	const { name: projectName, /*favorite,*/ id } = props;
	return <PCDiv>
		{/*<Link className="btn-settings" to={`/project/${id}/settings`}>
			<i className="fa fa-cogs" />
		</Link>*/}
		<div className="header">
			{/*<span>fav: {`${favorite}`}</span>
			<ToggleIcon
				className="btn-favourite"
				active={favourite}
				activeIcon="star"
				inactiveIcon="star-o"
				theme={{
					activeIconColor: '#fbc135',
					inactiveIconColor: '#fbc135',
					iconSize: '17px',
				}}
			/>*/}
			<h4>{projectName}</h4>
		</div>
		{/*<div className="statistics">
			<div className="counter">
				<span className="number">I DONT KNOW</span>
				<span className="desc">responses</span>
			</div>
		</div>*/}
		{/*<LineChart width={230} height={100} data={data}>
			<Line type="monotone" dataKey="y" stroke="#5a3fa1" strokeWidth={2} />
		</LineChart>*/}
		<Link className="btn-detail" to={`/project/${id}`}>
			<i className="fa fa-tv" /> View project
		</Link>
	</PCDiv>;
}

export default function Dashboard () {
	return <DDiv>
		<h2>My projects</h2>
		<div className="projects-list">
			{
				projects.map(project => (<ProjectCard key={project.id} {...project} />))
			}
			{/*<div className="btn-create-project">
				<Link to="/"><i className="fa fa-plus" /></Link>
			</div>*/}
		</div>
	</DDiv>;
}
