import { T_JSON } from "../modeling/types";

export const getModelFields = (modelSchema, languages, parentField = "", path = []) => {
	let result = [];
	for (const [fieldName, fieldDefinition] of Object.entries(modelSchema)) {
		if (fieldDefinition.systemField) {
			// console.log(fieldName+" is system field -> skipping");
			continue;
		}
		let title = parentField ? parentField + " - " + fieldName : fieldName;
		result.push({
			title,
			component: "title",
		});
		if (fieldDefinition.translatable) {
			for (let lang of languages) {
				let fieldPath = [lang, ...path, fieldName];
				/*if (fieldDefinition.type === T_JSON) {
					result.push({ component: "newLine" });
					let subFields = getModelFields(fieldDefinition.schema, languages, title, fieldPath);
					result = result.concat(subFields);
				} else*/ {

					result.push({
						fieldPath: fieldPath,
						type: fieldDefinition.type,
						component: fieldDefinition.type,
						title: lang,
						key: fieldPath.join(".") + "-" + lang,
						definition: fieldDefinition,
						placeholder: fieldName + " (" + lang + ")"
					});
				}
			}
		} else {
			let fieldPathNotTansl = [...path, fieldName];
			/*if (fieldDefinition.type === T_JSON) {
				result.push({ component: "newLine" });
				let subFields = getModelFields(fieldDefinition.schema, languages, title, fieldPathNotTansl);
				result = result.concat(subFields);
			} else */{
				result.push({
					fieldPath: fieldPathNotTansl,
					type: fieldDefinition.type,
					component: fieldDefinition.type,
					key: fieldPathNotTansl.join("."),
					definition: fieldDefinition,
					placeholder: fieldName,
				});
			}

		}

	}
	return result;
};
