import React, { useReducer, Component } from "react";
import { useDispatch } from "react-redux";
import { connect } from 'react-redux';
import queryString from 'query-string';

import { loginPassed } from "../session/actions";
import { url } from '../config';

const hostPath = url;

const loginStyle = {

	display: "flex",
	justifyContent: 'center',
	height: '100vh',
	alignItems: 'center',
  };

class LoginForm extends Component {
	 

	componentDidMount() {
		window.addEventListener('message', this.handleIframeTask);
	}

	handleIframeTask = (e) => {
		if (e.origin !== hostPath) {
			return;
		}
		if (e.data === 'loginSucceful') {
			this.props.loginPassed();
		}
	};

	render() {
		let params  = this.props.match;
		console.log("params:");
		console.log(params);
	
		let loginFormUrl = `${hostPath}/loginform`;
		const parsed = queryString.parse(this.props.location.search)
		const form = parsed.form;
		if (form){
			loginFormUrl = `${hostPath}/${form}`;
		}

		return (
			<div style={loginStyle}>

				<iframe
					frameBorder="0"
					src={loginFormUrl}
					width="350" height="350"
				/>
			</div>
			// <div>Hello</div>
		);
		
	}
}


const mapStateToProps = (state, props) => ({
	// user: getUserObject(state, props),
	// lang: state.locale ? state.locale.lang : 'en',
	// error: state.userState.get('error'),
});

const mapDispatchToProps = dispatch => ({
	// login(state) {
	// 	dispatch(login(state));
	// },
	loginPassed() {
		dispatch(loginPassed());
	},
	// clearLoginErrors() {
	// 	dispatch(clearLoginErrors());
	// },
	// localeSelected(locale) {
	// 	dispatch(localeSelected(locale));
	// },
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);

