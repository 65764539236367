import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Map as ImmuMap } from 'immutable';

import { crudo, actions as crudoActionNames, CrudoState } from "crudservice-client";


import useCrudoData from "../useCrudoData";
import useRouter from "../useRouter";
import { showNotice } from "../tools/notification";
import { validateFile } from "../tools/validateFile";
import Loader from "./Loader";
import Types, { T_FILE } from "../modeling/types";
import { removeButtonStyle } from "../Theme/styles";

/**
 * Create a Redux action for file upload
 * @optimize Pass the success action (or at least data) to success()
 * @param {FormData} form
 * @param {string} fileModel
 * @param {function} success
 * @param {function} failure
 * @returns {Object}
 */
const createUploadAction = (fileModel, form, success, failure) => {
	return {
		type: crudoActionNames.CREATE_DATA,
		objectName: fileModel,
		data: form,
		multipart: true,
		success,
		failure,
	};
};

/**
 * File preview and input field
 * @param {Object} props
 * @param {function} props.onChange
 * @param {*} props.state Current state
 * @param {Object} props.definition
 * @param {string} props.name
 * @param {MutableRefObject<boolean>} props.saved
 * @param {string} [props.parentName] Name of model property if isArray (because
 * props.name is going to be something like file_i0).
 */
export default function FileEditor (props) {
	const fileModel = useCrudoData(["_crudoConfig", "data", "fileModel"]);

	// load data for preview
	const reduxDispatch = useDispatch();
	useEffect(() => {
			// FIXME: optimize for performance
			if (props.state)
				reduxDispatch(crudo.getData(fileModel, props.state));
		}, [ reduxDispatch, props.state, fileModel ]
	);

	// count the number of times the value changes. -1 because the initial render triggers it too
	const timesChanged = useRef(-1);
	useEffect(() => {
		if (!props.saved.current) timesChanged.current++;
	}, [ props.state, props.saved, timesChanged ]);

	// delete files uploaded and not saved
	useEffect(() => {
		const { current } = timesChanged;
		function cleanupLingeringFiles (removeListener = true) {
			if (current < 1 || props.saved.current) return;
			// OPTIMIZE: remove the old file after successfully submitting a form with a new one
			reduxDispatch(crudo.deleteData(fileModel, props.state));
			if (removeListener) window.removeEventListener('beforeunload', cleanupLingeringFiles);
		}

		if (current > 0) {
			window.addEventListener('beforeunload', cleanupLingeringFiles);
		}
		return () => cleanupLingeringFiles(false);
	}, [ props.saved, timesChanged, fileModel, reduxDispatch, props.state ]);

	const removeFile = () => props.onChange(Types[T_FILE].empty);


	return <>
		{props.state ?
			<>
				<FilePreview fileModel={fileModel} data={props.state} type={props.definition.mimeType}/>
				{!props.definition.isArray && <button onClick={removeFile} style={removeButtonStyle}>×</button>}
			</>
		:
			<span>(no file)</span>
		}
		<FileUploader name={props.parentName || props.name} onChange={props.onChange} accept={props.definition.mimeType}/>
		<input type='hidden' value={props.state || ""}/>
		{/*<span>current file{props.definition.isArray ? 's' : null}: {props.state}</span>
		<RefEditor
			definition={{
				refModel: fileModel,
				isArray: props.definition.isArray,
			}}
			onChange={props.onChange}
			state={props.state}
		/>*/}
	</>;
}

/**
 * @param {Object} props
 * @param {function} props.onChange
 * @param {string} props.name
 * @param {string[]} [props.accept] Mimetype of accepted files
 */
function FileUploader (props) {

	const { match } = useRouter();
	const reduxDispatch = useDispatch();
	const [startedUpload, setStartedUpload] = useState(false);
	const fileModel = useCrudoData(["_crudoConfig", "data", "fileModel"]);

	/*const successHandler = successAction => {
		reduxDispatch({
			//type: 'CRUDO_GET_STRING_REPRESENTATIONS',
			type: 'CRUDO_LIST_DATA',
			objectName: 'file',
			listName: '_stringrep_file',
			query: { stringRepresentationOnly: true },
			success: props.onChange,
		});
	};*/
	// the above causes the form to rerender and thus lose values
	const successHandler = successAction => {
		// force the new value (new document's id) into the hidden input
		setStartedUpload(false);
		props.onChange(successAction.newId);
		showNotice(
			"File uploaded",
			"File uploaded successfully. Don't forget to save the document!",
			"success"
		);
		// also store the descriptor in redux
		reduxDispatch({
			type: "CRUDO_GET_DATA_SUCCESS",
			objectName: fileModel,
			data: successAction.response.descriptor,
			id: successAction.newId,
		});
	};

	/**
	 * Handle file upload to special endpoint using cs-c
	 * @param {Event} e
	 * @returns {void}
	 */
	async function uploadHandler (e) {
		const file = e.target.files[0];
		if (!file) {
			return showNotice(
				"No file selected",
				"Please select a file",
				"danger"
			);
		}

		const valid = await validateFile(file, props.accept);
		if (!valid) {
			const typeString = props.accept.map(s => `'${s}'`).join(" or ");
			return showNotice(
				"Invalid file type",
				`Please select a file matching one of the following types: ${typeString}.`,
				"danger"
			);
		}

		const fd = new FormData();
		fd.append("file", file);
		fd.append("model", match.params.modelName);
		fd.append("field", props.name);

		showNotice(
			"Uploading...",
			" ",
			"info"
		);
		setStartedUpload(true);
		//reduxDispatch(createUploadAction(fileModel, fd, successHandler, failureFnBELOW))
		reduxDispatch({
			type: "ADMINAPP_UPLOAD_FILE",
			form: fd,
			fileModel,
			onSuccess: successHandler,
			onFailure: () => {
				// TODO: pass the WHOLE error to onFailure (only one level deep passed now)
				// and get e.faultyFields.0 = { field(name), msg, reason }
				showNotice(
					"Upload failed",
					"The file could not be uploaded, please try again",
					"danger"
				);
				setStartedUpload(false);
			},
		});
	}

	return startedUpload ? <span>uploading</span> :
		<input type='file' name='file' accept={props.accept} onChange={uploadHandler}/>;
}

/**
 *
 * @param {Object} props
 * @param {string} props.fileModel
 * @param {*} props.data ID of the referenced document
 * @param {string} [props.type]
 */
function FilePreview (props) {

	// TODO: optimize for performance
	const base = useCrudoData(["_crudoConfig", "data", "staticUrlBase"], "/");
	const file = useCrudoData([props.fileModel, "data", props.data], ImmuMap()).toJS();

	if (file.crudoState !== CrudoState.OK || !file.type || !file.path) return <Loader />;

	//const path = useCrudoData([props.fileModel, "data", props.data, "path"], "");
	//const type = useCrudoData([props.fileModel, "data", props.data, "type"], "");
	//const file = useCrudoData([props.fileModel, "data", props.data], ImmuMap()).toJS();
	//console.log(props.data)
	//const resource = base + path;
	const resource = base + file.path;

	let prev;
	// FIXME: relying on .type here is not the best idea - only inferred; can't use def.mimeType as it is an array
	if (file.type.match(/^image\//)) {
		prev = <img alt={file.originalName} src={resource} style={{
			maxHeight: "300px",
			maxWidth: "300px",
		}}/>;
	} else if (file.type.match(/^audio\//)) {
		prev = <audio
			controls
			preload='metadata'
			src={resource}
		/>;
	} else {
		prev = <a
			href={resource}
			target='_blank'
			rel='noreferrer noopener'
		>(can't preview, click to view file)</a>;
	}
	return <>
		<a
			href={resource}
			target='_blank'
			rel='noreferrer noopener'
			style={{
				display: 'block',
			}}
		>
			"{file.originalName}"
		</a>
		{prev}
	</>;
}
