/*
 *
 * Login actions
 *
 */

import {
	DEFAULT_ACTION,
	LOGIN_USER,
	LOGIN_USER_PASSED,
	// LOGIN_USER_SUCCESS,
	// LOGIN_USER_FAIL,
	LOGOUT,
	GET_PROFILE,
	UPDATE_PROFILE,
	RESTORE_PASSWORD,
	CHANGE_LICENCE,
	CLEAR_LOGIN_ERROR,
	SET_ONBOARDING_GUIDE, FILTER_ITEMS,
} from './constants';

export function defaultAction() {
	return {
		type: DEFAULT_ACTION,
	};
}

export function login({ username, password }) {
	return {
		// types: [LOGIN_USER, LOGIN_USER_SUCCESS, LOGIN_USER_FAIL],
		type: LOGIN_USER,
		username,
		password,
	};
}


export function loginPassed() {
	return {
		// types: [LOGIN_USER, LOGIN_USER_SUCCESS, LOGIN_USER_FAIL],
		type: LOGIN_USER_PASSED,
	};
}


export function logout() {
	return {
		type: LOGOUT,
	};
}

export function updateProfile(data, success, fail) {
	return {
		type: UPDATE_PROFILE,
		data,
		success,
		fail,
	};
}

export function getProfile(success, fail) {
	return {
		type: GET_PROFILE,
		success,
		fail,
	};
}

export function restorePassword(data, success, fail) {
	return {
		type: RESTORE_PASSWORD,
		data,
		success,
		fail,
	};
}


export function clearLoginErrors() {
	return {
		type: CLEAR_LOGIN_ERROR,
	};
}

export function startTrialVersion(success, fail) {
	return {
		type: CHANGE_LICENCE,
		data: { licenceType: 'trial' },
		success,
		fail,
	};
}

export function setOnboardingGuide(guideId, data) {
	return {
		type: SET_ONBOARDING_GUIDE,
		onboardingGuide: {
			id: guideId, 
			initialData: data,
			ts: Date.now(), // i need to detect reseting of values
		}
	};
}
