/**
 * Read a (nested) object property by specifying its child path
 * @param {Object} object Root object (zeroth level)
 * @param {(string|string[])} path Path to the requested property
 * @param {*} [defaultValue=undefined] what to use in case of an exception
 * @returns {*} Value of the property or 'defaultValue' if it fails
 */
export function getDeepProperty (object, path, defaultValue = undefined) {
	try {
		if (Array.isArray(path)) path = path.join(".");
		if (path.length === 0) return object;
		let dot = path.indexOf(".");
		if (dot === -1) dot = path.length;
		const prop = path.slice(0, dot);
		const stillLeft = path.slice(dot + 1);
		if (stillLeft) {
			return getDeepProperty(object[prop], stillLeft, defaultValue);
		} else {
			return object[prop];
		}
	} catch (e) {
		return defaultValue;
	}
}
