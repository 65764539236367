import React  from 'react';
import { useDispatch } from 'react-redux';
import { submitButtonStyle } from '../Theme/styles';
import  BackButton from './BackButton'

/**
 * Form used for file uploads
 * @param {Object} props
 */
export default function FileUploadForm (props) {

	const reduxDispatch = useDispatch();

	/**
	 * Handle file upload to special endpoint using fetch
	 * @param {Event} ev
	 * @returns {void}
	 */
	function uploadHandler (ev) {
		ev.preventDefault();
		// TODO: verify that something was actually chosen and it matches requirements presented by server
		const fd = new FormData(ev.target);
		reduxDispatch({
			type: 'ADMINAPP_SAVE_DOCUMENT',
			form: fd,
			modelName: props.modelName,
			creating: true,
			translatable: false,
			// ? documentIdentifier
			// history: props.history,
			// projectId: props.match.params.projectId,
			onSuccess: () =>
				props.history.push(`/project/${props.match.params.projectId}/model/${props.modelName}/list`),

		});
	}

	return <form onSubmit={uploadHandler}>
		<h3>Upload a file</h3>
		<BackButton params={props.match.params} />
		<br />
		<input type='file' required='required' name='file' />
		<button type='submit' style={submitButtonStyle}>Upload</button>
	</form>;
}