import React, { Component } from 'react';
import styled from "styled-components";

import SidePanel from "./SidePanel";

import { cssWidth, sideBarLeftRightPadding } from "./SidePanel";

const AppStyler = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	min-height: 100vh;
`;

const Workspace = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	background-color: #EBEBEB;
	padding-left: ${cssWidth + 2 * sideBarLeftRightPadding + 40}px;
	padding-right: 40px;
	.layout-header {
		margin-top: 15px;
		margin-bottom: 15px;
		display: flex;
		flex-direction: row;
		align-items: center;
    justify-content: space-between;
		.user-panel {
			display: flex;
			align-items: center;
			span.name {
				margin-right: 20px;
			}
			img.avatar {
				width: 50px;
			}
		}
	}
	.content-wrapper {
		form {
			.input-group {
				margin-bottom: 20px;
				label {
					font-weight: 600;
					font-size: 14px;
					margin-bottom: 7px;
					display: inline-block;
					padding-left: 10px;
				}
				input {
					border-radius: 4px;
					padding: 15px 20px;
					border: none;
					font-size: 15px;
					width: 100px;
					display: block;
					box-shadow: 0px 1px 3px 0px #d8d8d8;
					&:focus {
						outline: none;
					}
				}
			}

			button {
				background-color: #4ba746;
				color: white;
				font-weight: 600;
				padding: 14px 35px;
				border-radius: 4px;
				border: 0px;
				font-size: 14px;
				text-decoration: none;
				margin-top: 15px;
				i {
					padding-right: 4px;
				}
			}
		}
	}
`;



export default (WrappedComponent) => {
	return class Wrapped extends Component {
		render() {
			// let userName = "xx";
			return (
				<AppStyler>
					<SidePanel />
					<Workspace>
						<div className="layout-header">
							<div className="user-panel">
								{/* <span className="name">Logged in as {userName}</span> */}

							</div>
						</div>

						<WrappedComponent {...this.props} />
					</Workspace>
				</AppStyler>
		  	)
		};
	}
}