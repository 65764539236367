import React, { useState } from "react";

/**
 * Replacement for Compytlo's checkbox that actually works.
 * @param props
 * @param {boolean} [props.initialValue=false]
 * @param {string} props.label
 * @param {function} [props.onChange]
 */
export default function CustomCheckbox (props) {
	//const { checked, setChecked } = props;
	const [checked, setChecked] = useState(props.initialValue || false); // TODO: lift state up!
	const onChange = e => {
		const status = e.target.checked;
		setChecked(status);
		if (props.onChange) props.onChange(status);
	};
	// TODO: unique id (needed for label htmlFor)
	return <div>
		<input
			type='checkbox'
			onChange={onChange}
			checked={checked}
			id={props.label ? `i_${props.label}` : null}
		/>
		{props.label ? <label htmlFor={`i_${props.label}`}>{props.label}</label> : null}
	</div>;
}
