/*
 *
 * Login reducer
 *
 */

import { fromJS } from 'immutable';
// import moment from 'moment';

import {
	DEFAULT_ACTION,
	LOGIN_USER,
	LOGIN_USER_SUCCESS,
	LOGIN_USER_FAIL,
	GET_PROFILE_SUCCESS,
	GET_PROFILE_FAIL,
	UPDATE_PROFILE_SUCCESS,
	LOGOUT,
	LOGOUT_SUCCESS,
	SET_SESSION_VALIDITY,
	SET_ONBOARDING_GUIDE,
} from './constants';

const initialState = fromJS({
	user: {
		userId: '',
		username: '',
		name: '',
		surname: '',
		language: '',
	},
	sessionValidity: false,
	error: '',
	loading: false,
	loaded: false,
	showLogout: false,
	onboardingGuide: null,
});


const customizeAppGlobalSetting = (userData) => {
	let appSetting = userData.appSetting;
	const lang = userData.language ? userData.language : 'en';
	if (appSetting && appSetting.hasOwnProperty('firstWeekDay')) {
		let firstWeekDay = parseInt(appSetting.firstWeekDay, 10) || 0;
		// this effects the first date in DateFields
		// moment.updateLocale(lang, {
		// 	week: {
		// 		dow: firstWeekDay,
		// 	},
		// });
	}
}

export const actionHandlers = {
	[DEFAULT_ACTION](state) {
		return state;
	},

	[LOGIN_USER](state) {
		return state.merge({
			loading: true,
			loaded: false,
			error: '',
			user: {},
		});
	},

	[SET_SESSION_VALIDITY](state, { sessionValidity }) {
		return state.merge({
			sessionValidity,
		});
	},

	[LOGIN_USER_SUCCESS](state, { userData }) {
		customizeAppGlobalSetting(userData);
		return state.merge({
			loading: false,
			loaded: true,
			sessionValidity: true,
			error: '',
			user: { ...userData },
		});
	},


	[GET_PROFILE_SUCCESS](state, { userData }) {
		customizeAppGlobalSetting(userData);
		return state.merge({
			user: { ...userData },
		});
	},

	[GET_PROFILE_FAIL](state, { error, callback }) {
		if (callback) {
			setTimeout(() => {
				callback(error);
			}, 10);
		}
		return state;
	},

	[UPDATE_PROFILE_SUCCESS](state, { userData, callback }) {
		customizeAppGlobalSetting(userData);
		const newUserData = Object.assign({}, state.get('user').toJS(), userData);
		const result = state.set('user', fromJS(newUserData));
		if (callback) {
			setTimeout(() => {
				callback(userData);
			}, 10);
		}
		return result;
	},

	[LOGIN_USER_FAIL](state, { error, callback }) {
		const failState = state.merge({
			loading: false,
			loaded: false,
			sessionValidity: false,
			error,
			user: {},
		});
		if (callback) {
			setTimeout(() => {
				callback(error);
			}, 10);
		}
		return failState;
	},

	[LOGOUT](state) {
		return state.merge({
			loading: true,
			loaded: false,
			sessionValidity: false,
			error: '',
			user: {},
		});
	},

	[LOGOUT_SUCCESS](state) {
		return state.merge({
			loading: false,
			loaded: false,
			showLogout: true,
			error: '',
		});
	},

	[SET_ONBOARDING_GUIDE](state, { onboardingGuide }) {
		return state.merge({
			onboardingGuide: onboardingGuide,
		});
	},
};

function loginReducer(state = initialState, action) {
	const { type } = action;
	if (type in actionHandlers) {
		return actionHandlers[type](state, action);
	}
	return state;
}

export default loginReducer;
