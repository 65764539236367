import React from 'react';
import styled from 'styled-components';

/**
 * Table page switching component
 *
 * @param {Object} props
 * @param {number} props.currentPage
 * @param {number} props.totalPages
 * @param {function} props.setPage
 */
export default function Paginator (props) {
	let pages;
	if (props.currentPage <= 3 || props.totalPages <= 5) {
		pages = [1, 2, 3, 4, 5];
	} else if (props.currentPage + 2 >= props.totalPages) {
		pages = [
			props.totalPages - 4,
			props.totalPages - 3,
			props.totalPages - 2,
			props.totalPages - 1,
			props.totalPages
		];
	} else {
		pages = [
			props.currentPage - 2,
			props.currentPage - 1,
			props.currentPage,
			props.currentPage + 1,
			props.currentPage + 2
		];
	}
	return <>
		<PaginationUl>
			<li key="p">Page:</li>
			{pages.map((n, i) => n <= props.totalPages ? <li key={i}>
					<button
						className={n === props.currentPage ? 'active' : ''}
						onClick={() => props.setPage(n)}
					>{n}</button>
				</li> : <li key={i}>&ndash;</li>
			)}
			<li key="t">(total {props.totalPages})</li>
		</PaginationUl>
	</>;
}

const PaginationUl = styled.ul`
	list-style: none;
	display: flex;
	& > li {
		margin-left: 3px;
	}
	& button.active {
		font-weight: bold;
		color: red;
	}
`;
