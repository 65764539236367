import { useSelector } from "react-redux";

/**
 * React hook for accessing CRUDO data
 * @param {string|string[]} path Either a property name or path
 * @param {*} [defaultValue]
 * @return {*}
 */
export default function useCrudoData (path, defaultValue) {
	try {
		const d = useSelector(state => state.crudo[Array.isArray(path) ? "getIn" : "get"](path));
		if (typeof d === "undefined") return defaultValue;
		return d;
	} catch (e) {
		return defaultValue;
	}
}
