import { call, put, takeEvery } from 'redux-saga/effects';
import { filterActions } from 'compytlo';
import Cookies from 'universal-cookie';

import { setCookie, getCookie } from './cookie';

import {
	LOGIN_USER,
	LOGIN_USER_SUCCESS,
	LOGIN_USER_FAIL,
	LOGIN_USER_PASSED,
	LOGOUT,
	LOGOUT_SUCCESS,
	LOGOUT_FAIL,
	GET_PROFILE,
	GET_PROFILE_SUCCESS,
	GET_PROFILE_FAIL,
	UPDATE_PROFILE,
	UPDATE_PROFILE_SUCCESS,
	UPDATE_PROFILE_FAIL,
	SET_SESSION_VALIDITY,
	CHECK_SESSION_VALIDITY,
	RESTORE_PASSWORD,
	CHANGE_LICENCE,
	// CLEAR_LOGIN_ERROR,
} from './constants';

import { url } from '../config';
const actions = { PROCESS_CALLBACKS: 'CRUDO_PROCESS_CALLBACKS'}

const COOKIE_SESSION = 'cs.sid';
const COOKIE_TIMESTAMP = 'cs.lgn.timestamp';
const COOKIE_LANGUAGE = 'language';
const COOKIE_MAX_AGE = 24 * 3600;
const DEFAULT_COOKIE_OPTIONS = { maxAge: COOKIE_MAX_AGE };

const getHeaders = () => {
	const headers = {
		Accept: 'application/json, text/plain, */*',
		'Content-Type': 'application/json',
	};
	return headers;
};

const API_GET_PROFILE = '/user/profile';
const API_UPDATE_PROFILE = '/user/update';

const getUserDataFromReponse = (profileResponse) => {
	const profileData = profileResponse.data;

	let userData = Object.assign({}, profileData);
	userData.userId = profileData['_id'];
	return userData;
};

function* checkAndProcessCallbacks(response) {
	if (response && response.callbacks && Array.isArray(response.callbacks)) {
		yield put({
			type: actions.PROCESS_CALLBACKS,
			callbacks: response.callbacks,
		});
	}
}

export function* loginPassed(args) {
	try {

		setCookie(COOKIE_TIMESTAMP, Date.now(), 24);

		let fetchResponse = yield call(fetch, url + API_GET_PROFILE, {
			method: 'GET',
			headers: getHeaders(),
			credentials: 'include',
		});

		const profileResponse = yield call([fetchResponse, fetchResponse.json]);
		if (!profileResponse || profileResponse.status !== 'success') {
			yield put({
				type: LOGIN_USER_FAIL,
				error: "Problem with getting user's profile in.",
			});
			return;
		}

		const userData = getUserDataFromReponse(profileResponse);
		// const error = { error: 'to je error user saga' };


		if (profileResponse) {
			// select language
			setCookie(COOKIE_LANGUAGE, userData.language, 12);
			
			// yield put({ type: LOCALE_SELECTED, locale: userData.language });
			yield put({ type: LOGIN_USER_SUCCESS, userData });
			// redirect();
			return;
		}
		// yield put({ type: LOGIN_USER_FAIL, error });
	} catch (error) {
		yield put({ type: LOGIN_USER_FAIL, error: error.error });
	}
}



export function* getProfile(args) {
	const { fail } = args;
	try {
		const fetchResponse = yield call(fetch, url + API_GET_PROFILE, {
			method: 'GET',
			headers: getHeaders(),
			credentials: 'include',
		});

		const profileResponse = yield call([fetchResponse, fetchResponse.json]);
		if (!profileResponse || profileResponse.status !== 'success') {
			yield put({
				type: GET_PROFILE_FAIL,
				error: "Problem with getting user's profile.",
				callback: fail,
			});
			return;
		}
		const userData = getUserDataFromReponse(profileResponse);
		
		yield put({ type: GET_PROFILE_SUCCESS, userData });
		return;
	} catch (error) {
		yield put({ type: GET_PROFILE_FAIL, error, callback: fail });
	}

	// /GET_PROFILE_SUCCESS
}
export function* updateProfile(args) {
	const { data, success, fail } = args;

	try {
		const fetchResponse = yield call(fetch, url + API_UPDATE_PROFILE, {
			method: 'PUT',
			headers: getHeaders(),
			credentials: 'include',
			body: JSON.stringify(data),
		});

		// ------------------------------------------------------------------------

		const response = yield call([fetchResponse, fetchResponse.json]);

		// ------------------------------------------------------------------------
		const error = { error: 'Problem with calling server' };
		if (response && response.status === 'success') {
			if (data.language) {
				setCookie(COOKIE_LANGUAGE, data.language, 12);
				// yield put({ type: LOCALE_SELECTED, locale: data.language });
			}
			yield put({
				type: UPDATE_PROFILE_SUCCESS,
				userData: data,
				callback: success,
			});
		} else {
			yield put({
				type: UPDATE_PROFILE_FAIL,
				error,
				callback: fail,
			});
		}
		yield* checkAndProcessCallbacks(response);
	} catch (exception) {
		yield put({
			type: UPDATE_PROFILE_FAIL,
			error: exception,
			callback: fail,
		});
	}
}
const removeCookies = (exceptLanguages) => {
	const cookies = new Cookies();
	cookies.remove(COOKIE_SESSION);
	cookies.remove(COOKIE_TIMESTAMP);

};

function* logout() {
	try {
		const error = { error: 'Saga logout error.' };
		const logouHeaders = getHeaders();
		removeCookies();
		const fetchResponse = yield call(fetch, `${url}/user/logout`, {
			method: 'GET',
			headers: logouHeaders,
			credentials: 'include',
		});

		const logoutResponse = yield call([fetchResponse, fetchResponse.json]);
		yield put({ type: LOGOUT_SUCCESS, ...logoutResponse });
		yield put({ type: filterActions.CLEAR_FILTERS });

		if (logoutResponse && logoutResponse.status === 'success') {
		} else {
			yield put({ type: LOGOUT_FAIL, error });
		}
	} catch (error) {
		yield put({ type: LOGOUT_FAIL, error });
	}
}

export function* checkSessionValidity() {
	// const sid = getCookie(COOKIE_SESSION);
	const loggedInAt = getCookie(COOKIE_TIMESTAMP);

	const sessionValidityApi = '/user/sessionvalidate';

	// if (sid !== null && loggedInAt !== null) {
	if (loggedInAt !== null) {
		const now = new Date();
		const timeDiff = Math.abs(now - parseInt(loggedInAt, 10));
		const diffHours = Math.floor(timeDiff / (1000 * 3600));

		if (diffHours >= 0) {
			yield put({ type: SET_SESSION_VALIDITY, sessionValidity: true });

			try {
				const fetchResponse = yield call(fetch, url + sessionValidityApi, {
					method: 'GET',
					headers: getHeaders(),
					credentials: 'include',
				});
				const validityResponse = yield call([fetchResponse, fetchResponse.json]);
				if (!validityResponse || validityResponse.status !== 'success') {
					yield put({ type: SET_SESSION_VALIDITY, sessionValidity: false });
				}
			} catch (e) {
				yield put({ type: SET_SESSION_VALIDITY, sessionValidity: false });
			}
		} else {
			yield put({ type: SET_SESSION_VALIDITY, sessionValidity: true });
		}
	} else {
		removeCookies(true);
		yield put({ type: SET_SESSION_VALIDITY, sessionValidity: false });
	}
}

export function* restorePassword(args) {
	const { data, success, fail } = args;

	try {
		const api = '/user/restorepassword';

		const fetchResponse = yield call(fetch, url + api, {
			method: 'POST',
			headers: getHeaders(),
			body: JSON.stringify(data),
		});

		// ------------------------------------------------------------------------

		const response = yield call([fetchResponse, fetchResponse.json]);

		// ------------------------------------------------------------------------
		let error = { error: 'Problem with calling server' };
		if (fetchResponse.status == 403) {
			error = { error: 'recaptcha' };
		} 
		if (response && response.status === 'success') {
			if (success) {
				// setTimeout(() => {
				success();
				// }, 10);
			}
		} else if (fail) fail(error);
		yield* checkAndProcessCallbacks(response);

	} catch (exception) {
		if (fail) fail(exception);
	}
}


// export current sagas
export const sessionSaga = [
	takeEvery(LOGIN_USER_PASSED, loginPassed),
	takeEvery(LOGOUT, logout),
	takeEvery(GET_PROFILE, getProfile),
	takeEvery(UPDATE_PROFILE, updateProfile),
	takeEvery(CHECK_SESSION_VALIDITY, checkSessionValidity),
	takeEvery(RESTORE_PASSWORD, restorePassword),

]
