import React, { useCallback, useState, useReducer } from "react";
import { AuthState, crudoReducer, CrudoState, getCudoSaga } from "crudservice-client";
import { applyMiddleware, combineReducers, createStore, compose } from "redux";
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import createSagaMiddleware from "redux-saga";
import { all, put } from "redux-saga/effects";
import { Provider, useSelector } from "react-redux";
import sessionReducer from './session/reducer';
import { url } from './config';
/*import { ThemeProvider } from 'styled-components';
import { defaultThemeProps } from 'compytlo';*/
import produce from "immer";

import { BrowserRouter as Router, Route, Switch, } from "react-router-dom";

import styled from "styled-components";

import { sessionSaga } from './session/saga';
import { CHECK_SESSION_VALIDITY } from "./session/constants";


import adminAppEffects from "./sagas";
import LoginForm from "./screens/LoginForm";

import routes from './routes';
import { adminAppReducer, initialState as appInitialState } from "./redux/modelTable/reducers";



const crudObjects = [];

function* adminAppSagas () {
	yield all(adminAppEffects);
}

const sagaMiddleware = createSagaMiddleware();
const enhancer = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose)(applyMiddleware(
	sagaMiddleware,
));


const combinedReducers = combineReducers({
	crudo: crudoReducer(crudObjects),
	userState: sessionReducer,
	adminApp: adminAppReducer,
});
const store = window.store = createStore(combinedReducers, {
	adminApp: appInitialState,
}, enhancer);


const allCrudoSagas = (function getAllSagas(crudoConfig) {
	const crudoSagas = getCudoSaga(crudoConfig);

	return function* allSagas() {
		yield all([...sessionSaga, ...crudoSagas]);
		yield put({ type: CHECK_SESSION_VALIDITY });
		/*// Initialize after page reload
		yield put({ type: LOCALE_SELECTED, locale: getCookie('language') });*/
	};
})({
	url,
	servers: { default: url },
	objectsMap: {},
	crudObjects: [],
});

sagaMiddleware.run(allCrudoSagas);
sagaMiddleware.run(adminAppSagas);


function App () {
	return <Router>
		<React.StrictMode>
			<Switch>
				{routes.map(route => (
					<Route
						key={route.path}
						{...route}
					/>
				))}
			</Switch>
		</React.StrictMode>
	</Router>;
}

export default function () {
	return <Provider store={store}>
		<ReactNotification />
		<App />
	</Provider>;
}
