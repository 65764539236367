import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Map as ImmuMap } from 'immutable';
import styled from 'styled-components';
//const styled = {div: str => props => <div>{props.children}</div>}

const MCDiv = styled.div`
	position: relative;
	padding: 30px 20px;
	background-color: white;
	border: 1px solid #eaeaea;
	border-radius: 4px;
	width: 230px;
	margin-right: 20px;
	text-align: center;
	box-shadow: 0px 0px 5px 0px #e0e0e0;
	.btn-settings {
		opacity: 0;
		transition: opacity 0.1s ease-in;
		color: #2d2d2d;
		font-size: 20px;
		position: absolute;
		right: 15px;
		top: 15px;
	}
	.header {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;
		margin-top: 10px;
		.btn-favourite {
			margin-right: 10px;
		}
		h4 {
			margin: 0px;
			font-size: 20px;
			font-weight: 400;
			color: #593fa1;
		}
	}
	.statistics {
		margin-bottom: 40px;
		display: flex;
		align-items: center;
		position: relative;
		justify-content: center;
		.counter {
			transition: transform 0.1s ease-in, opacity 0.2s ease;
			.number {
				display: block;
				font-weight: 600;
				font-size: 45px;
				color: #5a3fa1;
			}
			.desc {
				font-size: 12px;
				color: gray;
				font-weight: 300;
			}
		}
		.btn-responses {
			transition: opacity 0.1s ease-in;
			position: absolute;
			opacity: 0;
			line-height: 25px;
			top: 10px;
			width: 100%;
			text-align: center;
			color: #2d2d2d;
			font-weight: bold;
			font-size: 12px;
			text-decoration: none;
			i {
				font-size: 38px;
				display: block;
			}
		}
	}
	&:hover {
		.btn-settings {
			opacity: 1;
		}
		.counter {
			opacity: 0;
			transform: translateX(-20px);
		}
		.btn-responses {
			opacity: 1;
		}
	}
	.btn-detail {
		background-color: #fbc135;
		color: #10100c;
		padding: 12px 25px;
		border-radius: 4px;
		font-size: 13px;
		font-weight: 600;
		text-decoration: none;
		margin-top: 30px;
		display: inline-block;
		&:hover {
			background-color: #f1af11;
		}
	}
`;

const DDiv = styled.div`
	h2 {
		font-size: 28px;
		font-weight: 800;
		margin-left: 15px;
	}
	.projects-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
	}
`;

function ModelCard (props) {
	const { name: modelName, projectId, /*favorite,*/ } = props;
	return <MCDiv>
		{/*<Link className="btn-settings" to={`/project/${id}/settings`}>
			<i className="fa fa-cogs" />
		</Link>*/}
		<div className="header">
			{/*<span>fav: {`${favorite}`}</span>
			<ToggleIcon
				className="btn-favourite"
				active={favourite}
				activeIcon="star"
				inactiveIcon="star-o"
				theme={{
					activeIconColor: '#fbc135',
					inactiveIconColor: '#fbc135',
					iconSize: '17px',
				}}
			/>*/}
			<h4>{modelName}</h4>
		</div>
		{/*<div className="statistics">
			<div className="counter">
				<span className="number">I DONT KNOW</span>
				<span className="desc">responses</span>
			</div>
		</div>*/}
		{/*<LineChart width={230} height={100} data={data}>
			<Line type="monotone" dataKey="y" stroke="#5a3fa1" strokeWidth={2} />
		</LineChart>*/}
		<Link className="btn-detail" to={`/project/${projectId}/model/${modelName}/list`}>
			<i className="fa fa-tv" /> View records
		</Link>
	</MCDiv>;
}

function modelNameComparator(a, b) {
	return a.name < b.name
}

export default function ProjectDashboard (props) {
	const dispatch = useDispatch();
	useEffect(
		() => {
			dispatch({ type: 'CRUDO_GET_SERVER_CONFIG' })
		},
		[ dispatch ]
	);
	/** @type {string} */const projectName = props.match.params.projectId;
	/** @type {string[]} */const models = [...useSelector(state => state.crudo.keys() || [])].filter(m => m[0] !== '_').sort(modelNameComparator);
	return <DDiv>
		<h2>Models{/* of project {projectName} */}</h2>
		<div className="projects-list">
			{
				models.sort().map(model => (<ModelCard key={model} name={model} projectId={props.match.params.projectId} />))
			}
			{/*<div className="btn-create-project">
				<Link to="/"><i className="fa fa-plus" /></Link>
			</div>*/}
		</div>
	</DDiv>
}
