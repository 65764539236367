/**
 * Save selected columns in a model table
 * @param {string} model
 * @param {string[]} selectedColumns
 * @returns {void}
 */
export function saveColumns (model, selectedColumns) {
	localStorage.setItem(`aui_columns_${model}`, JSON.stringify(selectedColumns));
}

/**
 * Get selected table columns for a given model
 * @param {string} model
 * @returns {string[]}
 */
export function loadColumns (model) {
	const defaultColumns = ['_id'];
	// TODO, this should be configurable per model

	const got = localStorage.getItem(`aui_columns_${model}`);
	try {
		return got ? JSON.parse(got) : defaultColumns;
	} catch (e) {
		return defaultColumns;
	}
}
