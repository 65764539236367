import { createSelector } from 'reselect';

// const selectUserDomain = () => (state) => {
//   if (state) {
//     console.log('neco');
//     return state.get('userState');
//   }
//   return undefined;
// }
export const getUserDomain = state => state.userState;
const getUserObject = createSelector([getUserDomain], userState => userState.toJS());
export const getUserProperty = (state, propertyPath) => state.userState.getIn(['user', ...propertyPath]);


const getFormDomain = state => state.form;

const getLoginFormValues = createSelector([getFormDomain], (formState) => {
	const ret =
		formState && formState.hasIn(['loginForm', 'registeredFields'])
			? formState.getIn(['loginForm', 'registeredFields'])
			: undefined;
	if (ret) {
		return ret.toJS();
	}
	return ret;
});

const getSelectIsLoggedIn = createSelector(
	[getUserDomain],
	userState => userState.get('sessionValidity'),
);

const getSelectIsInitializingAuth = createSelector(
	[getUserDomain],
	userState => !!(userState && userState.getIn(['loading'])),
);

export { getUserObject, getLoginFormValues, getSelectIsLoggedIn, getSelectIsInitializingAuth };
