import * as types from "./types";
import produce from "immer";


/**
 * @typedef {Object} AdminAppState
 */

/** @type {AdminAppState} */
export const initialState = {
};


/**
 * @typedef {Object} AdminAppAction
 * @property {string} type
 */

/**
 * @param {AdminAppState} state
 * @param {AdminAppAction} action
 */
const actionHandlers = {
	[types.ADMINAPP_SHOW_MODEL_TABLE](state, {modelName}) {
		return produce(state, state => {
			state.modelName = modelName;
		});
	},
};

export const adminAppReducer = (state = initialState, action) => {
	const {type} = action;
	if (type in actionHandlers) {
		return actionHandlers[type](state, action);
	}
	return state;
};
