import React, { useRef, useEffect } from "react";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertFromHTML, convertToRaw, EditorState, ContentState } from 'draft-js';
import { Editor }  from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import Loader from "./Loader";

/**
 * @param {Object} props
 * @param {function} props.onChange
 * @param {string} props.initialState Starting HTML string state
 */
export const Wysiwyg = (props) => {
	// prevent slow rerenders by caching the initial state
	const first = useRef(true);
	const initialState = useRef(null);
	useEffect(() => {
		if (!first.current) return;

		const convertedFromHtml = convertFromHTML(props.initialState || "");
		initialState.current = EditorState.createWithContent(ContentState.createFromBlockArray(
			convertedFromHtml.contentBlocks,
			convertedFromHtml.entityMap,
		));
		first.current = false;
	})

	// however, pull all state changes back to root reducer asap
	const changeHandler = (newEditorState) => {
		props.onChange(
			draftToHtml(convertToRaw(newEditorState.getCurrentContent())),
			{ valid: true }
		);
	}

	if (initialState.current === null)
		return <Loader />

	return <Editor
		defaultEditorState={initialState.current}
		onEditorStateChange={changeHandler}
	/>
}
