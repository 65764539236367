import React from 'react';
/**
 * Web routes definitions with controlled access
 */
// import {
// 	IsAppUp
// } from 'effento-hyperon';

import {
	UserIsAuthenticatedRedirect,
	UserIsNotAuthenticatedRedirect,
	// AuthehticateRootDir,
} from './session/authWrapper';

import Dashboard from './screens/Dashboard';
import LoginForm from './screens/LoginForm';
import ModelTable from './screens/ModelTable';
import ProjectDashboard from './screens/ProjectDashboard';
import EditFormScreen from "./screens/EditFormScreen";

import layoutWrapper from './components/LayoutWrapper';

const WrappedEditForm = layoutWrapper(EditFormScreen);

const DEFAULT_PAGE = 'project/0'; 
// const pingUrl = '/images/ping.bmp';
const routes = [
	{
		path: '/',
		// component: AuthehticateRootDir(Scheduler),
		component: () => {
			window.location = `/${DEFAULT_PAGE}`;
			return null;
		},
		// component: DefaultPage,
		exact: true,
	}, {
		path: '/login',
		component: UserIsNotAuthenticatedRedirect(LoginForm),
		exact: false,
	}, {
		path: '/logout',
		component: UserIsNotAuthenticatedRedirect(LoginForm),
		exact: false,
	}, {
		path: '/dashboard',
		component: UserIsAuthenticatedRedirect(layoutWrapper(Dashboard)),
		exact: false,
	}, {
		path: '/project/:projectId/model/:modelName/list',
		component: UserIsAuthenticatedRedirect(layoutWrapper(ModelTable)),
		exact: false,
	},{
		path:[
			//'/model/:model/(create|edit/:id)'
			'/project/:projectId/model/:modelName/create',
			'/project/:projectId/model/:modelName/edit/:documentId',
		],
		render: (props) => {
			let docId = null;
			try {
				docId = props.match.params.id || null;
			} catch (e) {
				console.log('failed to access doc id');
			}
			return <WrappedEditForm
				modelName={props.match.params.modelName}
				documentIdentifier={props.match.params.documentId || null}
				{...props}
			/>
		}
	}, {
		path: '/project/:projectId',
		component: UserIsAuthenticatedRedirect(layoutWrapper(ProjectDashboard)),
		exact: false,
	}
	
];

export default routes;
