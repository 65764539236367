export const buttonStyle = {
	color: "white",
	fontWeight: "600",
	borderRadius: "4px",
	border: "0px",
	cursor: "pointer",
};

export const submitButtonStyle = {
	...buttonStyle,
	backgroundColor: "#4ba746",
	color: "white",
	padding: "14px 35px",
	fontSize: "14px",
	marginTop: "15px",
};

export const removeButtonStyle = {
	...buttonStyle,
	backgroundColor: "red",
	fontSize: "18px",
	padding: "5px 1em",
	marginLeft: "10px",
};
