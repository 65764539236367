import TYPES from '../../modeling/types'; // This should be a part of crud-types instead.

/**
 * Prepare the document data to be inserted as previous values into the form.
 * @param {ImmuMap} immuMap
 * @param {Object} modelSchema
 * @param {boolean} translatable
 * @param {string[]} langs
 * @param {boolean} [nested=false] Called recursively?
 * @returns {Object}
 */
export function processInitialState(immuMap, modelSchema, translatable, langs, nested) {
  const initialState = immuMap.toJS();

  // remove crudo things
  delete initialState._id;
  delete initialState._meta;
  delete initialState.crudoState; //?

  const defaultInitialState = {
    _dirty: false,
    _displayIndices: {},
    ...(translatable ? langs.reduce(
      (prev, lang) => ({
        ...prev,
        [lang]: {},
      }),
      {}
    ) : {})
  };

  return Object.entries(modelSchema).reduce(
    (accu, [name, def]) => {
      if (def.translatable) {
        // TODO docs: this only supports primitives like strings etc, not arrays, refs, ...
        if (nested) {
          // should NEVER happen, FIXME
        } else {
          for (const lang of langs) {
            const langObj = initialState[lang] || {};
            const val = langObj[name];

            if (typeof val !== 'undefined') {
              // all ok
              accu[lang][name] = val;
            } else {
              // check if there is a default value on the document itself (not nested)
              if (typeof initialState[name] !== 'undefined') {
                accu[lang][name] = initialState[name];
              } else {
                // otherwise there really is no value -> use default of type
                accu[lang][name] = TYPES[def.type].empty;
              }
            }
          }
        }
        return accu;
      }

      const value = initialState[name];
      if (typeof value !== 'undefined') {
        // convert resolved refs (in the format of { str, _id }) to ids
        if (def.type === 'ref' || def.type === 'file') {
          accu[name] = def.isArray ? value.map((el) => el._id) : value._id;
        } else {
          accu[name] = value;
        }
        if (def.isArray) {
          // prepare default display indices for all elements
          accu._displayIndices[name] = {};
          for (let i = 0; i < value.length; i++) {
            accu._displayIndices[name][i] = i;
          }
        }
      } else {
        if (def.isArray) {
          accu[name] = [];
          accu._displayIndices[name] = {};
        } else if (def.type === 'json') {
          // call self on the child schema
          accu[name] = processInitialState(
            { toJS: () => ({}) },
            def.schema,
            translatable && def.translatable,
            langs,
            true
          );
        } else {
          accu[name] = TYPES[def.type].empty;
        }
      }
      return accu;
    },
    nested ? {} : defaultInitialState
  );
  // _dirty is used an indicator of whether the form's data has changed
}
