import { store } from 'react-notifications-component';

/**
 * Show notification, which will dissaper after 5 sec.
 * 
 * @param {*} title 
 * @param {*} message 
 * @param {*} type  [success|danger|info|default|warning]
 */
export function showNotice(title, message, type = 'default') {
	store.addNotification({
		title: title,
		message: message,
		type: type,
		insert: "top",
		container: "top-right",
		animationIn: ["animated", "fadeIn"],
		animationOut: ["animated", "fadeOut"],
		width: 300,
		dismiss: {
			duration: 5000,
			// onScreen: true
		}
	});
	

}
