import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Button to return from edit form.
 * @param {Object} props
 * @param {Object} props.params
 */
export default function BackButton ({ params }) {
	return <Link style={{
		textDecoration: 'none',
		fontSize: '15px',
		marginBottom: '35px',
		display: 'inline-block',
		//marginLeft: '10px',
		fontWeight: '600',
		color: '#5b2ca4',
		marginRight: 10,
	}} to={`/project/${params.projectId}/model/${params.modelName}/list`}>
		<i className="fa fa-mail-reply" /> Return back
	</Link>;
}
