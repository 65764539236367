export const T_STRING = "string";
export const T_JSON = "json";
export const T_NUMBER = "float";
export const T_INT = "int";
export const T_BOOL = "boolean";
export const T_MONGO_ID = "mongoid";
export const T_REF = "ref";
export const T_FILE = "file";

/**
 * @typedef {Object} TypeEmptyObject
 * @property {*} empty Value that represents an empty input field on the frontend (eg. '' for a string)
 */

/**
 * @type {Object.<string, TypeEmptyObject>}
 */
const e = {
	[T_STRING]: {
		empty: null,
	},
	[T_MONGO_ID]: {
		empty: null,
	},
	[T_NUMBER]: {
		empty: null,
	},
	[T_INT]: {
		empty: null,
	},
	[T_BOOL]: {
		empty: null,
	},
	[T_REF]: {
		empty: null,
	},
	[T_FILE]: {
		empty: null,
	},
	[T_JSON]: {
		empty: {},
	},

};

export default e;
