import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CrudoState } from "crudservice-client";

import FileUploadForm from '../components/FileUploadForm';
import ClassicEditForm from '../components/ClassicEditForm';
import Loader from "../components/Loader";
import useRouter from '../useRouter';


const EDIT_FORMS = {
	file: FileUploadForm,
	default: ClassicEditForm,
}

/**
 * Wrapper deciding whether to render the standard edit form or a file uploader
 * @param {Object} props
 * @param {string} props.modelName
 * @param {(string|null)} props.documentIdentifier
 */
export default function EditFormWrapper(props) {
	/*
	- crudo selector doesn't work used here (with or without hooky memoization)
	const selector = useMemo(() => getCrudData('_crudoConfig'), []);
	const { fileModel } = selector();*/

	const router = useRouter();
	let m = router.match.params.modelName || null;
	const modelReady = useSelector(state => {
		return state.crudo.getIn([m, 'definition', 'crudoState'], CrudoState.INITIAL) === CrudoState.OK
			&& state.crudo.getIn([m, 'crudoState'], CrudoState.INITIAL) === CrudoState.OK
	});

	const configReady = useSelector(state => {
		return !!state.crudo.getIn(['_crudoConfig', 'data', 'languages'], undefined);
	});

	const meta = useSelector(state => {
		return state.crudo.getIn([m, 'definition', 'meta'], undefined);
	});

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch({ type: 'CRUDO_GET_SERVER_CONFIG' });
	},[ dispatch ]);

	useEffect(() => {
		dispatch({ type: 'CRUDO_LIST_DATA', objectName: m, query: { ignoreTranslation: 1 } });
	}, [ dispatch, m ]);

	const fileModel = useSelector(state => state.crudo.getIn(['_crudoConfig', 'data', 'fileModel']));

	// component key, that will be used for editing data
	let componentForEdit = 'default';

	if (props.modelName === fileModel){
		componentForEdit =  'file';
	}
	
	if (meta && meta["component"]) {
		componentForEdit = meta["component"];	
	}

	// the class factory - get the proper componet for editing data
	let EditorComponent = (EDIT_FORMS[componentForEdit]) ? EDIT_FORMS[componentForEdit] : ClassicEditForm;
	


	if (!m || !modelReady || !configReady) return <Loader visible icon="spinner" title="LOADER" />

	return <EditorComponent {...props} />;
}

//export default withRouter(EditForm); // TODO: why? we can't go back to the tableview till we have an OK response
