import { edit as COMPONENTS } from "../components/fieldComponents";
import ArrayEditor from "../components/ArrayEditor";
import React from "react";
import styled from "styled-components";
import { getDeepProperty } from "./getDeepProperty";

const InputGroup = styled.div`
	margin-bottom: 20px;
	label {
		font-weight: 600;
		font-size: 14px;
		margin-bottom: 7px;
		display: inline-block;
		padding-left: 10px;
	}
	input {
		border-radius: 4px;
		padding: 15px 20px;
		border: none;
		font-size: 15px;
		minWidth: 50px;
		display: block;
		box-shadow: 0px 1px 3px 0px #d8d8d8;
	}
`;

const HintComponent = styled.div`
	font-weight: 100;
	color: gray;
	p {
		margin: 0px;
		margin-left: 40px;
	}

`

export function generateInputGroup (field, fIdx, localDispatch, localState, saved) {
	if (field.component === "title") {
		return (<label htmlFor={field.title} key={`superlabel_${field.title}`}>{field.title}</label>);
	}
	if (field.component === "newLine") {
		return (<br key={fIdx + ""}/>);
	}
	const DesiredComponent = COMPONENTS[field.component];
	if (!DesiredComponent) return null;

	/**
	 * Dispatch a 'change' action to the form reducer
	 * @param {*} value
	 * @param {Object} validationState
	 * @returns {void}
	 */
	function onInputChange (value, validationState) {
		const toDispatch = {
			type: "change",
			fieldPath: field.fieldPath,
			value: /*e.target.*/value,
			validationState,
		};
		// if (lang) toDispatch.language = lang;
		localDispatch(toDispatch);
	}

	let enumComponent = null;
	if (field.definition && field.definition.enum) {
		let enums = field.definition.enum.join(",");
		enumComponent = (<HintComponent> {"Enum: [" + enums + "]"} </HintComponent>);
	}

	let descriptionComponent = null;
	if (field.definition && field.definition.description) {
		let content = field.definition.description.split("\n").map((i, index) => {
			if (index > 0) {
				return (<p>{i}</p>);
			}
			return i;
		});
		descriptionComponent = (<HintComponent> {"Description: "} {content} </HintComponent>);
	}

	const useArrayEditor = field.type === "ref" ? false : field.definition.isArray;

	return (
		<InputGroup key={field.key}>
			{field.title ?
				<label htmlFor={!field.definition.isArray && field.key ? field.key : null}>{field.title}</label> : null}
			{descriptionComponent}
			{enumComponent}
			{useArrayEditor ? <ArrayEditor
				state={getDeepProperty(localState, field.fieldPath, [])}
				displayIndices={localState._displayIndices[field.fieldPath]}
				formDispatch={localDispatch}
				k={field.key}
				{...field}
				saved={saved}
			/> : <DesiredComponent
				name={field.title || field.key}
				// state={localState.getIn([...field.fieldPath, 'value'])}
				state={getDeepProperty(localState, field.fieldPath)}
				onChange={onInputChange}
				placeholder={field.placeholder}
				key={field.key}
				definition={field.definition}
				saved={saved}
				fieldPath={field.fieldPath}
			/>}
		</InputGroup>
	);
}
