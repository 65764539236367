import React from "react";
import { List as ImmuList } from "immutable";
import { SelectGroup, SelectOption } from "compytlo";

import types, { T_BOOL, T_FILE, T_INT, T_JSON, T_MONGO_ID, T_NUMBER, T_REF, T_STRING } from "../modeling/types";
import CustomCheckbox from "./CustomCheckbox";
import useCrudoData from "../useCrudoData";
import FileEditor from "./FileEditor";
import JsonEditor from "./JsonEditor";
import { Wysiwyg } from "./Wysiwyg";

const validateNumberType = (val, transmute = true) => {
	if (typeof val !== "number") {
		if (typeof val === "string" && transmute) {
			let parsed = parseFloat(val);
			if (!isNaN(parsed)) return parsed;
		}
		throw new Error("hm");
	} else {
		return val;
	}
};


/**
 * String input field
 * @param {Object} props
 * @param {string} props.name Name of the field
 * @param {function} props.onChange
 * @param {string} props.state The actual state
 * @param {Object} props.definition
 */
const textEditor = (props) => {

	if (props.definition.editor === 'wysiwyg') {
		return <Wysiwyg initialState={props.state} onChange={props.onChange} />
	}

	const inputAttributes = {};
	if (props.definition/*.criteria*/) {
		// FIXME: this smells like WET. Can't we have a generalized way of doing this?
		// What about unpacking the criteria object? min,max,minLength,..., - except enum it should be okay
		// minLength, maxLength doesn't work properly, see below for custom validation logic
		if (props.definition/*.criteria*/.hasOwnProperty('maxLength')) inputAttributes['maxLength'] = props.definition/*.criteria*/.maxLength;
		if (props.definition/*.criteria*/.hasOwnProperty('minLength')) inputAttributes['minLength'] = props.definition/*.criteria*/.minLength;

		if (props.definition.required) inputAttributes.required = true;
	}

	/**
	 * Handle input change by calling props.onChange after internal validation.
	 * @param {HTMLInputElement} target
	 * @returns {void}
	 */
	function changeHandler({ target }) {
		if (!target) return;
		const { value } = target;
		let valid = target.checkValidity();

		if (!props.definition/*.criteria*/) props.definition/*.criteria */ = {};

		// js validation, in case the native one doesn't catch something; FIXME: prevent from throwing by always defining criteria
		// TODO: take notice of what is wrong and pass it to either the DOM or the form (to show the user an error)
		if (props.definition/*.criteria*/.hasOwnProperty('maxLength') && value.length > props.definition/*.criteria*/.maxLength) valid = false;
		if (props.definition/*.criteria*/.hasOwnProperty('minLength') && value.length < props.definition/*.criteria*/.minLength) valid = false;

		props.onChange(value, {
			valid,
			//message: 'hello world',
		});
	}

	function preventEnter(e) {
		if (e.charCode == 13) {
			e.preventDefault();
			return;
		}
	}

	const { component } = props.definition;
	let inputType = 'text';
	let componentStyle = undefined;
	if (component == "multiline") {

		return <textarea
			id={props.name}
			value={props.state}
			style={styles.multiline}
			// onKeyPress={preventEnter}
			onChange={changeHandler}
			placeholder={props.definition.defaultValue || props.placeholder || props.name}
			{...inputAttributes}
		/>;

	}


	return <input
		id={props.name}
		value={props.state}
		onChange={changeHandler}
		placeholder={props.definition.defaultValue || props.placeholder || props.name}
		{...inputAttributes}
	/>;
}

const styles = {
	multiline: {
		width: "90%",
		height: 250,
	},
};


const numberEditor = (props) => {
	const inputAttributes = {};
	if (props.definition/*.criteria*/) {
		// FIXME: this smells like WET. Can't we have a generalized way of doing this?
		// What about unpacking the criteria object? min,max,minLength,..., - except enum it should be okay
		if (props.definition/*.criteria*/.hasOwnProperty('max')) inputAttributes['max'] = props.definition/*.criteria*/.max;
		if (props.definition/*.criteria*/.hasOwnProperty('min')) inputAttributes['min'] = props.definition/*.criteria*/.min;

		if (props.definition.required) inputAttributes.required = true;
	}

	/**
	 * Handle input change by calling props.onChange after internal validation.
	 * @param {HTMLInputElement} target
	 * @returns {void}
	 */
	function changeHandler({ target }) {
		let valid = target.checkValidity();
		let v = target.value;
		// js validation, in case the native one doesn't catch something
		if (props.required && v === '') valid = false; // FIXME: why? this is useless.

		try {
			v = validateNumberType(v);
		} catch (e) {
			//debugger;
			valid = false; // crud-types-based validation failed, this can't be good
		}
		if (v === '') v = types[T_NUMBER].empty;

		props.onChange(v, {
			valid,
			//message: 'boo scary error',
		});
	}

	return <input
		type='number'
		value={props.state}
		onChange={changeHandler}
		placeholder={props.definition.defaultValue || props.placeholder || props.name}
		{...inputAttributes}
	/>;
}
// This should ideally be a part of either CC or CT. It includes validation logic based on crud config, which makes it unsuitable for Compytlo.
// I believe that the validation logic should be directly called from the component and not its parents.

/**
 * DB reference input field
 * @param {Object} props
 * @param {function} props.onChange
 * @param {*} props.state Current state
 * @param {Object} props.definition
 *
 */
function RefEditor(props) {
	const opts = useCrudoData([`_stringrep_${props.definition.refModel}`, 'list'], ImmuList())
		.toJS()
		.map(({ _id, str }) =>/* ({_id,str}));*/<SelectOption label={str} value={_id} key={_id} />);

	const changeHandler = props.definition.isArray ? props.onChange : selected => props.onChange(selected[0]);

	// TODO: style me
	return <SelectGroup
		displayClearButton={true}
		max={props.definition.isArray ? props.definition.maxElements || null : null}
		multiple={!!props.definition.isArray}
		onChange={changeHandler} // might have to add something extra - see other editors' source
		selectedItems={props.definition.isArray ? props.state : [props.state]}
	>
		{opts}
	</SelectGroup>;
}

const submitButtonStyle = {
	backgroundColor: '#fba746',
	color: 'white',
	fontWeight: '600',
	padding: '14px 35px',
	borderRadius: '4px',
	border: '0px',
	fontSize: '14px',
	marginTop: '15px',
	cursor: 'pointer',
};

export const edit = {
	/*String: function (props) {
		//console.log(`rendering string input with val ${props.state}`)
		return <input type='text' value={props.state} onChange={props.setState||props.onChange} placeholder={props.name} />;
	},*/
	[T_STRING]: textEditor,
	[T_MONGO_ID]: textEditor,
	[T_NUMBER]: numberEditor,
	[T_INT]: numberEditor,
	[T_BOOL]: function (props) {
		return <CustomCheckbox
			initialValue={typeof props.state !== 'undefined' ? props.state : props.definition.defaultValue || false}
			onChange={bool => props.onChange(bool, { valid: true })} // checkboxes kinda can't be invalid
		/>
	},
	[T_REF]: RefEditor,
	[T_FILE]: FileEditor,
	[T_JSON]: JsonEditor,
};

export const table = {
	[T_STRING]: function StringPreview (props) {
		return <span>{props.value}</span>;
	},
	[T_MONGO_ID]: function MongoIdPreview (props) {
		return <span>{props.value}</span>;
	},
	[T_NUMBER]: function NumberPreview (props) {
		return <span>{props.value}</span>;
	},
	[T_INT]: function IntPreview (props) {
		return <span>{props.value}</span>;
	},
	[T_BOOL]: function BoolPreview (props) {
		return <span>{typeof props.value !== 'undefined' ? props.value.toString() : ''}</span>;
	},
	[T_JSON]: function JsonPreview (props) {
		return <span>{JSON.stringify(props.value)}</span>;
	},
	[T_REF]: function RefPreview (props) {
		if (!props.value) return <span />;
		// the above never actually shows to the user but the app crashes if not included and an empty array ref is set
		return <span title={props.value._id}>{props.value.str}</span>
	},
	[T_FILE]: function FilePreview (props) {
		if (!props.value || !props.value._id) return <span />;
		return <span title={props.value._id} dangerouslySetInnerHTML={{ __html: props.value.str }} />
	},
};
