import { takeEvery, put, putResolve, take, select, race } from 'redux-saga/effects';
import { Map as ImmuMap } from 'immutable';

import { actions as crudoActions } from 'crudservice-client';
import { showNotice } from "./tools/notification";

/*/!**
 * SAGA: Change view to model table of the chosen model(Name)
 * @param {string} modelName
 *!/
function* showModelTable ({ modelName }) {
	// load model definition
	yield put({
		type: crudoActions.LOAD_MODEL_DEFINITION,
		modelName,
	})
	yield take(crudoActions.LOAD_MODEL_DEFINITION_SUCCESS)
	// load model documents
	yield put({
		type: crudoActions.LIST_DATA,
		objectName: modelName,
	})
	// actually switch to the table view
	yield take(crudoActions.LIST_DATA_SUCCESS)
	yield put({
		type: 'ADMINAPP_SHOW_MODEL_TABLE',
		modelName,
	})
}*/

/*/!**
 * SAGA: load data into edit form
 * @param {string} modelName
 * @param {string} documentId
 * @deprecated as it does nothing. might change in the future - this saga is probably the right one to load data from server if it's not local yet
 *!/
function* startEditingDocument ({ modelName, documentId }) {
	// TODO: try getting the doc (if already downloaded) from redux store, if not, then get it whole from server
	// currently we know that we have the document (because of the way docs are loaded and stored) so we can access it


	/!*const doc = yield select(state => state.crudo.getIn([modelName, 'data', documentId]));
	yield put({
		type: 'ADMINAPP_ACTUALLY_SHOW_FORM',
	})*!/
}*/

/**
 * SAGA: save changes to document
 * @param {string} modelName
 * @param {boolean} creating
 * @param {string} [documentIdentifier]
 * @param {(FormData|Object)} form Immer stabilized object containing the form fields OR FormData instance
 * @param {boolean} [translatable] If working with multiple language model
 * @param {string[]} [languages] If translatable, array of language code strings
 * @param {function} [onSuccess]
 * @param {function} [onFailure]
 * @param {*} [rawModelSchema] Model schema/definition
 */
function* saveDocument ({
	modelName,
	documentIdentifier,
	form,
	creating,
	translatable,
	onSuccess,
	onFailure,
	languages,
	rawModelSchema,
}) {
	// construct something that can be sent
	let formData;

	if (form instanceof FormData) {
		// uploading a file, already in a format that can be used as a fetch body
		formData = form;
	} else {
		// reconstruct a form dictionary based on input keys and values
	// if (!translatable) {
		const doReduce = (accu, [fieldName, val]) => {
			// _dirty is used an indicator of whether the form's data has changed
			// and _displayIndices for keeping arrays up-to-date visually but not in data instantly
			if (fieldName === '_dirty' || fieldName === '_displayIndices') return accu;

			// force object unfreeze
			const jsoned = JSON.stringify(val);
			let v = JSON.parse(typeof jsoned === 'undefined' ? 'null' : jsoned);

			const isRef = rawModelSchema && rawModelSchema[fieldName] && rawModelSchema[fieldName].type === 'ref';
			if (Array.isArray(v) && form._displayIndices[fieldName] && !isRef) {
				const prevV = [...v];
				v = new Array(form._displayIndices[fieldName].length).fill(null);
				for (const [index, pos] of Object.entries(form._displayIndices[fieldName])) {
					if (pos !== -1)
						v[pos] = prevV[index];
				}
				v = v.filter(e => e !== null && typeof e !== 'undefined');
			}

			return {
				...accu,
				[fieldName]: v,
			};
		};
		formData = Object.entries(form).reduce(doReduce, {});
	// } else {
	// 	formData = Object.entries(form.toJS()).reduce((accu, [ langCode, langForm ]) => {
	// 		return {
	// 			...accu,
	// 			[langCode]: Object.entries(langForm).reduce((innerAccu, [fieldName, fieldVal]) => {
	// 				return {
	// 					...innerAccu,
	// 					[fieldName]: fieldVal,
	// 				};
	// 			}, {}),
	// 		};
	// 	}, {});
	// }
	}
	if (creating) {
		const action = {
			type: crudoActions.CREATE_DATA,
			objectName: modelName,
			data: formData,
		};
		if (formData instanceof FormData) {
			// instruct crudo client to treat this request in a multipart way
			action.multipart = true;
		}
		yield put(action);
	} else {
		// use crudo to update the document both clientside and serverside
		yield put({
			type: crudoActions.UPDATE_DATA,
			objectName: modelName,
			data: formData,
			documentIdentifier,
		});
	}
	const SUCCESS_ACTION = creating ? crudoActions.CREATE_DATA_SUCCESS : crudoActions.UPDATE_DATA_SUCCESS;
	const FAILURE_ACTION = creating ? crudoActions.CREATE_DATA_FAIL : crudoActions.UPDATE_DATA_FAIL;
	const { success, failure } = yield race({
		success: take(action =>
			action.type === SUCCESS_ACTION && action.objectName === modelName),
		failure: take(action =>
			action.type === FAILURE_ACTION && action.objectName === modelName),
	});
	if (failure) {
		// don't navigate away, show validation errors
		console.log(failure)
		if (failure.response) {
			if (failure.response.description === 'validation-error' && failure.response.hasOwnProperty('faultyFields')) {
				const str = failure.response.faultyFields.reduce((accu, {description}) => {
					// FIXME: the ' ' should be a newline but neither \n nor <br/> works since it's escaped & placed in a <p/>
					return accu + ' ' + description;
				}, '');
				yield showNotice(
					'Save failed',
					str,
					'danger'
				);
			} else {
				let messageStr = failure.response.description ? failure.response.description : 'The document could not be saved.';
				yield showNotice(
					'Save failed',
					messageStr,
					'danger'
				);
			}
		}
		if (onFailure) yield onFailure(failure);
	} else {
		yield showNotice(
			creating ? 'Created' : 'Updated',
			`Document has been ${creating ? 'created' : 'updated'} successfully.`,
			'success'
		);

		let docId = creating ? success.newId : documentIdentifier;

		yield put({
			type: crudoActions.INVALIDATE_DATA,
			objectName: modelName,
			ids: [ docId ],
		});

		if (onSuccess) yield onSuccess(success);

		/*// fetch the new table, if applicable
		yield put({
			type: crudoActions.LIST_DATA,
			objectName: modelName,
			query: {
				ignoreTranslation: 1,
			},
		});*/
	}
}


/**
 * SAGA: delete a document
 * @param modelName
 * @param documentIdentifier
 */
function* deleteDocument ({ modelName, documentIdentifier }) {
	yield put({
		type: crudoActions.DELETE_DATA,
		objectName: modelName,
		documentIdentifier,
	});
	const { success, failure } = yield race({
		success: take(crudoActions.DELETE_DATA_SUCCESS),
		failure: take(crudoActions.DELETE_DATA_FAIL),
	}); // this is an attempt to solve the issue above
	if (failure) {
		console.error(failure);
		// TODO show a legit error message
	} else if (success) {
		// table refresh not required, crudo manages it properly
	} else {
		console.warn('neither success nor failure in deleteDocument?');
	}
}

function* alertFailedLogin (...args) {
	console.error(...args)
	yield alert('Login failed')
}

/**
 * SAGA: upload a file
 * @param {Object} a
 * @param {FormData} form
 * @param {string} fileModel
 * @param {function} onSuccess
 * @param {function} onFailure
 * @todo replace this with a direct call to crudo's CREATE_DATA (has to call fail/success with more args)
 */
function* uploadFile ({ form, fileModel, onSuccess, onFailure }) {
	yield put({
		type: crudoActions.CREATE_DATA,
		objectName: fileModel,
		data: form,
		multipart: true,
	});
	const { success, failure } = yield race({
		success: take(action =>
			action.type === crudoActions.CREATE_DATA_SUCCESS && action.objectName === fileModel),
		failure: take(action =>
			action.type === crudoActions.CREATE_DATA_FAIL && action.objectName === fileModel),
	});
	if (failure) {
		onFailure(failure);
	} else {
		onSuccess(success);
		// don't navigate but supply data to preview
	}
}

// what is being exported are effects
export default [
	//takeEvery('ADMINAPP_SELECT_MODEL', showModelTable),
	//takeEvery('ADMINAPP_EDIT_DOCUMENT', startEditingDocument),
	takeEvery('ADMINAPP_SAVE_DOCUMENT', saveDocument),
	takeEvery('ADMINAPP_UPLOAD_FILE', uploadFile),
	takeEvery('ADMINAPP_DELETE_DOCUMENT', deleteDocument),
	takeEvery(crudoActions.AUTHENTICATE_FAILURE, alertFailedLogin),
]
