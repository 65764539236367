import React, { useCallback, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

//import { Column, Table, SortDirection } from 'react-window';
/*import { Column, Table, SortDirection } from 'react-virtualized';
import AutoSizer from 'react-virtualized-auto-sizer';*/
//const styled = {div: str => props => <div>{props.children}</div>}
//import StyledComponent from './styles';
//import { universalDateFormater } from './utils';
import { List, Map as ImmuMap } from "immutable";
import produce from 'immer';
import { CrudoState } from "crudservice-client";
//import 'react-virtualized/styles.css';
import styled from "styled-components";
import useCrudoData from "../useCrudoData";
import Loader from "../components/Loader";
import useRouter from "../useRouter";

import { table as COMPONENTS } from "../components/fieldComponents";
import CustomCheckbox from "../components/CustomCheckbox";
import { T_MONGO_ID, T_STRING } from "../modeling/types";
import { showNotice } from "../tools/notification";
import { loadColumns, saveColumns } from "../tools/columnManagement";
import Paginator from "../components/Paginator";
import { pageSize } from "../config";

function NoRows () {
	return <div className='no-rows'>No rows</div>;
}

// RAW HTML
export default ModelTableViewWrapper;


/**
 * @typedef {Object} Col
 * @property {string} name
 * @property {string} type
 * @property {boolean} [translatable]
 * @property {boolean} [isArray] Is array of the given .type?
 * @todo {string} desc
 */
/**
 * Table view of a model's documents
 * @param props
 * @param {string} props.modelName
 * @param {Object} props.state
 * @param {string[]} props.state.checkedRows
 * @param {string[]} props.state.checkedColumns
 * @param {function} props.dispatch
 * @param {number} props.page
 */
function ModelTable (props) {

	const parentDispatch = props.dispatch;

	const router = useRouter();
	//debugger;
	const modelDefinition = useCrudoData([ props.modelName, 'definition', 'data' ]);
	const colNames = props.state.checkedColumns;
	/** @type {Col[]} */ let cols = colNames.map(colName => {
		if (colName === '_id') return {
			name: colName,
			type: T_MONGO_ID,
			translatable: false,
			isArray: false,
		};
		if (!modelDefinition[colName]) {
			if(colName.startsWith("_meta")){
				return {
					name: colName,
					type: T_STRING,
					translatable: false,
					isArray: false,
				}
			}
			return null;
		}
		return {
			name: colName,
			type: modelDefinition[colName].type,
			translatable: modelDefinition[colName].translatable || false,
			isArray: modelDefinition[colName].isArray || false,
		};
	}).filter(col => !!col);
	/** @type {List<Object>} */
	let allDocs = useCrudoData([ props.modelName, 'data' ], ImmuMap({})).toList() || List();
	const translatable = useCrudoData([props.modelName,'translatable'], false);

	const dispatch = useDispatch()

	/**
	 * Dispatch a document delete action to Redux
	 * @type {function}
	 * @param {Object} Action, excluding type
	 * @returns {void}
	 */
	const dispatchDelete = useCallback(
		(arg) => dispatch({
			type: 'ADMINAPP_DELETE_DOCUMENT',
			modelName: props.modelName,
			...arg,
		}),
		[ dispatch ],
	);


	const { projectId } = router.match.params;

	// apply pagination
	const docs = allDocs.slice(
		(props.page - 1) * pageSize,
		props.page * pageSize
	);

	const children = docs.toJS().map(( d, i ) => {
		/**
		 * (not Dispatch a document edit action and) navigate to the edit form
		 * @returns {void}
		 */
		const editFn = () => {
			router.history.push(`/project/${projectId}/model/${props.modelName}/edit/${d._id}`);
			//dispatchEdit({ _id: d._id, });
		}
		const deleteFn = () => {
			if (window.confirm('You are about to delete table row, continue?')) {
				dispatchDelete({documentIdentifier: d._id});
			}
		};
		return <DocumentRow
			translatable={translatable}
			doc={d}
			cols={cols}
			key={i}
			onEdit={editFn}
			onDelete={deleteFn}
			onCheck={(rowId, value) => parentDispatch({ type: 'change_row_checked', rowId, value })}
		/>
	})
	return <Table
		bordered
		columns={[
			{ name: '', css: { width: '20px' }},
			...cols.map(({ name }) => ({ name })),
			{ name: 'actions', css: { width: '175px' }}
		]}
	>
		{children}
	</Table>
}

/**
 * Wrapper for ModelTable due to 'react-hooks/rules-of-hooks' disallowing conditional hook calls
 * @param props
 * @param {string} props.modelName
 */
function ModelTableViewWrapper (props) {
	const router = useRouter();
	let m = router.match.params.modelName || null;
	const modelReady = useSelector(state => {
		return state.crudo.getIn([ m, 'definition', 'crudoState' ], CrudoState.INITIAL) === CrudoState.OK
			&& state.crudo.getIn([ m, 'crudoState' ], CrudoState.INITIAL) === CrudoState.OK
	});

	const configReady = useSelector(state => {
		return !!state.crudo.getIn(['_crudoConfig', 'data', 'languages'], undefined);
	});

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch({ type: 'CRUDO_GET_SERVER_CONFIG' });
	},[ dispatch ]);
	// FIXME: only get config if not already available
	useEffect(() => {
		dispatch({ type: 'CRUDO_LIST_DATA', objectName: m, query: { ignoreTranslation: 1 } });
	}, [ dispatch, m ]);


	if (!m || !modelReady || !configReady) return <Loader visible icon="spinner" title="LOADER"/>
	return <ModelTableView />;
}

const TableActionsStyler = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: flex-end;
	margin: 15px 0px;
	button {
		width: 200px;
		max-width: 250px;
		margin: 5px 0px;
		border: 1px solid gray;
		border-radius: 6px;
		&:focus, &:hover {
			background-color: lightgray;
		}
		&.cta {
			background-color: #01da01;
			&:focus, &:hover {
				background-color: #01c701;
			}
		}
	}
`;

/**
 * Houses the view of a given model's table. Modelname is made sure not to be null before this component.
 * @param props
 */
function ModelTableView (props) {
	const router = useRouter();

	const { projectId, modelName } = router.match.params;

	const [ localState, localDispatch ] = useReducer(
		/**
		 * @typedef {Object} MTRowCheckedAction
		 * @property {'change_row_checked'} type
		 * @property {boolean} value
		 * @property {string} rowId
		 */
		/**
		 * @typedef {Object} MTColumnCheckedAction
		 * @property {'change_column_checked'} type
		 * @property {string} column
		 * @property {boolean} selected
		 */
		/**
		 * @param {Object} state
		 * @param {(MTRowCheckedAction|MTColumnCheckedAction)} action
		 * @returns {Object} state
		 */
		function modelTableReducer (state, action) {
			switch (action.type) {
				case 'change_row_checked':
					return produce(state, state => {
						if (action.value)
							state.checkedRows.push(action.rowId);
						else
							state.checkedRows = state.checkedRows.filter(v => v !== action.rowId);
					});
				case 'change_column_checked':
					return produce(state, state => {
						if (action.selected) {
							state.checkedColumns.push(action.column);
						} else {
							state.checkedColumns = state.checkedColumns.filter(
								v => v !== action.column
							);
						}
						saveColumns(modelName, state.checkedColumns);
					});
				default:
					return state;
			}
		}, {
			checkedRows: [],
			checkedColumns: loadColumns(modelName),
		}
	);

	function copyCheckedIds () {
		const x = localState.checkedRows;
		if (!x || !x.length) {
			showNotice('Oops', 'Select some items first. Use the checkboxes.', 'warning');
			return;
		}
		if ('writeText' in navigator.clipboard) {
			navigator.clipboard.writeText(x.join(','));
			showNotice("Great!",`Copied ${x.length} ids to clipboard. Now you can use the button '+ from clipboard'`);
		} else {
			// ie and edge don't support this api
			prompt(
				'Your browser doesn\'t support writing to the clipboard directly. Please copy the below manually (^A, ^C):',
				x.join(',')
			);
		}
		// alert(`Copied ${x.length} ids to clipboard.`);
		return false;
	}

	const currentPage = parseInt(
		new URLSearchParams(router.location.search).get('page') || '1'
	);
	const setPage = newPage => router.history.push(
		`/project/${projectId}/model/${modelName}/list?page=${newPage}`
	);
	const totalPages = Math.ceil(
		useCrudoData([modelName, 'data'], ImmuMap()).size / pageSize
	);

	return <>
		<h2>{modelName}</h2>
		<ColumnSelector modelName={modelName} localDispatch={localDispatch} state={localState} />
		{/*<button onClick={dispatch}>create new {props.modelName}</button>*/}
		{/*<EditButton modelName={props.modelName} dispatch={dispatchCreate}>create new {props.modelName}</EditButton>*/}
		<TableActionsStyler>
			<button className="cta" onClick={() => router.history.push(`/project/${projectId}/model/${modelName}/create`)}>create new {modelName}</button>
			<button onClick={copyCheckedIds}>copy ids of all selected rows</button>
		</TableActionsStyler>
		<Paginator
			currentPage={currentPage}
			setPage={setPage}
			totalPages={totalPages}
		/>
		<ModelTable
			modelName={modelName}
			state={localState}
			dispatch={localDispatch}
			page={currentPage}
		/>
		<Paginator
			currentPage={currentPage}
			setPage={setPage}
			totalPages={totalPages}
		/>
	</>;
}

const ColumnSelectorStyler = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	div {
		width: 200px;
	}
`;

/**
 * UI component facilitating the selection of displayed table columns
 * @param props
 * @param {string} props.modelName
 * @param {function} props.localDispatch
 * @param {Object} props.state
 */
function ColumnSelector (props) {
	const modelDefinition = useCrudoData([ props.modelName, 'definition', 'data' ])


	const dispatchCheckbox = useCallback(
		(column, selected) => props.localDispatch({
			type: 'change_column_checked',
			column,
			selected,
		}),
		[ props ]
	);
	const children = [
		// OPTIMIZE: this might not be desired (defaults should be configurable)
		<CustomCheckbox
			initialValue={props.state.checkedColumns.includes('_id')}
			label='_id'
			key='_id'
			onChange={b => dispatchCheckbox('_id', b)}
		/>,
		<CustomCheckbox
			initialValue={props.state.checkedColumns.includes('_meta.createdAt')}
			label='createdAt'
			key='_meta.createdAt'
			onChange={b => dispatchCheckbox('_meta.createdAt', b)}
		/>,
		<CustomCheckbox
			initialValue={props.state.checkedColumns.includes('_meta.modifiedAt')}
			label='modifiedAt'
			key='_meta.modifiedAt'
			onChange={b => dispatchCheckbox('_meta.modifiedAt', b)}
		/>,
	...Object.keys(modelDefinition).map(f => <CustomCheckbox
			initialValue={props.state.checkedColumns.includes(f)}
			label={f}
			key={f}
			onChange={b => dispatchCheckbox(f, b)}
		/>),
	];
	return (
		<div>
			Columns:
			<ColumnSelectorStyler>
				{children}
			</ColumnSelectorStyler>
		</div>
	);
}

//const CustomCheckboxStyler = styled.div``;

const Cell = styled.td`
	overflow: hidden;
	text-overflow: ellipsis;
`;

const getValue = (doc, lang, field) => {
	return doc[lang] && doc[lang][field];
}
/**
 * Generic row
 * @param props
 * @param {Col[]} props.cols
 * @param {Object} props.doc
 * @param {function} [props.onEdit] If provided, the row will have an extra column containing an edit button whose onClick will be bound to this
 * @param {function} [props.onDelete]
 * @param {function} [props.onCheck]
 * @param {boolean} [props.translatable]
 */
function DocumentRow (props) {
	const documentColumns = /*props.doc.mapEntries((accu, v) => {
		accu.push(v)
		return accu
	}, [])*/
	props.cols.map(({ name }) => _.get(props.doc,name));

	let actions = [];
	if (props.onEdit) {
		// entry is editable - insert edit button
		actions.push(<button onClick={props.onEdit} key='edit'>edit</button>)
	}
	if (props.onDelete)
		actions.push(<button onClick={props.onDelete} key='delete'>delete</button>);

	// if (props.onCheck)
	// 	actions.push(<CustomCheckbox key='check' onChange={checked => props.onCheck(props.doc._id, checked)} />);

	const actionColumn = actions.length ? <td style={{ display: 'flex' }}>{actions}</td> : null;

	const langs = useCrudoData([ '_crudoConfig', 'data', 'languages' ], [null]);

	return (
		<tr>
			<td><CustomCheckbox key='check' onChange={checked => props.onCheck(props.doc._id, checked)} /></td>
			{documentColumns.map((value, columnIndex) => {
				const col = props.cols[columnIndex];

				const DesiredComponent = COMPONENTS[col.type];
				const isFieldTranslatable = col.translatable && props.translatable;
				const langAgnosticValIterable = col.isArray ? value : [ value ];
				const hasLangAgnosticVal = langAgnosticValIterable
					&& langAgnosticValIterable.length
					&& langAgnosticValIterable.some(el => typeof el !== 'undefined');
				let atLeastOneTranslationMissing = false;

				if (!DesiredComponent) return <Cell key={col.name}>unknown type '{col.type}'</Cell>;

				let children = [];

				if (isFieldTranslatable) {

					for (const lang of langs) {
						const thisLangChildren = [];
						let actualContentElementsPushed = 0;

						thisLangChildren.push(`${lang}: `);

						if (col.isArray)
							thisLangChildren.push('[');

						const translatedVal = getValue(props.doc, lang, col.name);
						const valueIterable = col.isArray ? translatedVal : [ translatedVal ];

						if (valueIterable.length) {
							for (const thisValue of valueIterable) {
									if (typeof thisValue !== 'undefined') {
										actualContentElementsPushed++;
										thisLangChildren.push(
											<DesiredComponent value={thisValue} key={lang}/>,
											', '
										);
									} else {
										atLeastOneTranslationMissing = true;
									}
							}
							if (thisLangChildren[thisLangChildren.length - 1] === ', ')
								thisLangChildren.pop();
						}

						if (actualContentElementsPushed === 0) {
							if (col.isArray) {
								thisLangChildren.push(']');
							} else {
								thisLangChildren.push(<span key={`empty${lang}`} style={{color: 'orange'}}>(empty)</span>)
							}
						}
						thisLangChildren.push(<br key={'br' + lang} />);
						children.push(...thisLangChildren);
					}

					if (hasLangAgnosticVal || atLeastOneTranslationMissing) {
						children.push('default: ');
					} else {
						// remove last br if no default
						children.pop();
					}
				}

				if (col.isArray)
					children.push('[');

				if (hasLangAgnosticVal) {
					children.push(
						...langAgnosticValIterable.reduce((accu, thisValue, thisIndex) => [
							...accu,
							<DesiredComponent key={thisIndex} value={thisValue}/>,
							', '
						], []),
					);
					children.pop(); // rmeove last comma
				} else {
					if (!isFieldTranslatable || atLeastOneTranslationMissing)
						children.push(<span style={{color: 'orange'}} key="emptyla">(empty)</span>);
				}

				if (col.isArray)
					children.push(']');

				return <Cell key={col.name}>
					{children}
				</Cell>;
			})}
			{actionColumn}
		</tr>
	)
}

/**
 * Generic table
 * @param props
 * @param {string[]} props.columns
 * @param props.children
 * @param {boolean} props.bordered
 */
function Table (props) {
	return <table border={props.bordered ? '1' : null} style={{
		width: '100%',
		whiteSpace: 'nowrap',
		tableLayout: 'fixed',
		marginBottom: '15px',
		border: '1px solid black',
		borderCollapse: 'collapse',
	}}>
		<thead>
			{/*<tr>
				<th>Well yes</th>
				<th>But actually</th>
				<th>No</th>
			</tr>*/}
			<tr>
				{props.columns.map(({ name, css }) => <th key={name} style={css || {}}>{name}</th>)}
			</tr>
		</thead>
		<tbody>
			{props.children}
		</tbody>
	</table>
}
