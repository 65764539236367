/*
 *
 * Login constants
 *
 */
export const PREFIX = 'app/Login/';
export const DEFAULT_ACTION = `${PREFIX}DEFAULT_ACTION`;
export const LOGIN_USER = `${PREFIX}LOGIN_USER`;
export const LOGIN_USER_SUCCESS = `${PREFIX}LOGIN_USER_SUCCESS`;
export const LOGIN_USER_FAIL = `${PREFIX}LOGIN_USER_FAIL`;
export const SET_SESSION_VALIDITY = `${PREFIX}SET_SESSION_VALIDITY`;
export const CHECK_SESSION_VALIDITY = `${PREFIX}CHECK_SESSION_VALIDITY`;

export const LOGIN_USER_PASSED = `${PREFIX}LOGIN_USER_PASSED`;

export const GET_PROFILE = `${PREFIX}GET_PROFILE`;
export const GET_PROFILE_SUCCESS = `${PREFIX}GET_PROFILE_SUCCESS`;
export const GET_PROFILE_FAIL = `${PREFIX}GET_PROFILE_FAIL`;
export const UPDATE_PROFILE = `${PREFIX}UPDATE_PROFILE`;
export const UPDATE_PROFILE_SUCCESS = `${PREFIX}UPDATE_PROFILE_SUCCESS`;
export const UPDATE_PROFILE_FAIL = `${PREFIX}UPDATE_PROFILE_FAIL`;

export const RESTORE_PASSWORD = `${PREFIX}RESTORE_PASSWORD`;
export const RESTORE_PASSWORD_SUCCESS = `${PREFIX}RESTORE_PASSWORD_SUCCESS`;
export const RESTORE_PASSWORD_FAIL = `${PREFIX}RESTORE_PASSWORD_FAIL`;

export const CHANGE_LICENCE = `${PREFIX}CHANGE_LICENCE`;
export const CHANGE_LICENCE_SUCCESS = `${PREFIX}CHANGE_LICENCE_SUCCESS`;
export const CHANGE_LICENCE_FAIL = `${PREFIX}CHANGE_LICENCE_FAIL`;

export const LOGOUT = `${PREFIX}LOGOUT`;
export const LOGOUT_SUCCESS = `${PREFIX}LOGOUT_SUCCESS`;
export const LOGOUT_FAIL = `${PREFIX}LOGOUT_FAIL`;

export const CLEAR_LOGIN_ERROR = `${PREFIX}CLEAR_LOGIN_ERROR`;

export const SET_ONBOARDING_GUIDE = `${PREFIX}SET_ONBOARDING_GUID`;
