const FileType = require('file-type/browser');
const { mimeTypes: supportedTypes } = require('file-type');
const mime = require('mime-types');

/**
 * Attempt to verify that the selected file matches the server-defined type
 * @param {File} file
 * @param {string[]} types
 * @returns {Promise<boolean>}
 */
export async function validateFile (file, types) {
	// if any, just pass
	if (!types || !types.length) return true;

	// validate extension first
	//if (/*!mime.extensions[type] ||*/ !mime.extensions[type].includes(ext)) return false;
	const ext = file.name.split('.').pop();
	//if (!types.map(type => mime.extensions[type]).some(extensions => extensions.includes(ext))) return false;
	const assumedTypes = types.filter(type => mime.extensions[type].includes(ext));
	if (!assumedTypes.length) return false; // ? can .length be >1 ?

	// check if can check header

	//const typeRegex = new RegExp(types.map(t => `(${t})`).join('|')); // ['x/y','a/b'] => /(x/y)|(a/b)/
	let assumedTypesSupported = assumedTypes.filter(t => supportedTypes.has(t));

	if (!assumedTypesSupported.length) {
		// the file-type library doesn't support checking this file type, pass
		return true;
	}

	// check file header
	const parsed = await FileType.fromBlob(file);

	return parsed && assumedTypesSupported.includes(parsed.mime);
}
