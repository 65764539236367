import React from 'react';
import styled from 'styled-components';

import { edit as COMPONENTS } from './fieldComponents';
import TYPES from '../modeling/types';
import { showNotice } from '../tools/notification';

/**
 * Note to self: using a local reducer for data won't do us any good because we need the data
 * to be available in the parent reducer and not just here. Eg. the 'state' prop should always
 * reflect what is actually seen and rendered.
 */

const ArrayEditorContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const Row = styled.div`
	display: flex;
	& > * {
		flex: 1;
	}
	& > label {
		max-width: 50px;
		line-height: 40px;
		/* TODO: center vertically */
	}
	& > input {
		width: calc(100% - 200px);
	}
	& > button {
		max-width: 50px;
	}
	.addButton {
		max-width: 100px;
	}
	:last-of-type {
		justify-content: right;
		button {
			height: 45px;
		}
	}
`;

/**
 * Represents an array field
 * @param {Object} props
 * @param {string} props.type
 * @param {function} props.formDispatch Dispatch method of the form reducer
 * @param {*[]} props.state
 * @param {string} props.component
 * @param {*} props.fieldPath
 * @param {Object.<number,number>} props.displayIndices Display indices for this field
 */
export default function ArrayEditor(props) {

	const DesiredComp = COMPONENTS[props.component];
	if (!DesiredComp) return null;

	/**
	 * Dispatch a change action to the form reducer
	 * @param {number} index Index of the element in the array
	 * @param {*} value
	 * @param {Object} validationState
	 * @returns {void}
	 */
	function onChildInputChange(index, value, validationState) {
		props.formDispatch({
			type: 'change-array',
			//fieldPath: [ ...props.fieldPath, index ],
			fieldPath: props.fieldPath,
			index,
			value: value,
			validationState, // TODO: test whether this works as expected
		});
	}

	/**
	 * Remove a single array element (dispatch the array without the element to the form reducer)
	 * @param {number} toRemove Index of the element to remove in the underlying array
	 * @returns {void}
	 */
	function onDeleteButtonClick(toRemove) {
		props.formDispatch({
			type: 'change-position',
			fieldPath: props.fieldPath,
			index: toRemove,
			targetPosition: -1,
		});
	}

	/**
	 * Move an array element either up or down.
	 * @param {number} index Factual index of the element in the underlying array
	 * @param {number} targetPosition Desired display position after the move
	 * @returns {void}
	 */
	function onArrowButtonClick(index, targetPosition) {
		props.formDispatch({
			type: 'change-position',
			fieldPath: props.fieldPath,
			index,
			targetPosition,
		});
	}

	/**
	 * Add a new element to the array
	 * @returns {void}
	 */
	function addElement() {
		/*props.formDispatch({
			type: 'change',
			fieldPath: props.fieldPath,
			value: [ ...props.state, TYPES[props.definition.type].empty || ''],
		});*/
		let index = props && props.state && props.state.length ? props.state.length : 0;
		if (index >= props.definition.maxElements) {
			// don't proceed
			showNotice(
				'Can\'t add a new item',
				`This field (${props.k}) has a maximum of ${props.definition.maxElements} items.`,
				'warning'
			);
			return;
		}
		const t = TYPES[props.definition.type];
		props.formDispatch({
			type: 'change-array',
			fieldPath: props.fieldPath,
			index: index,
			value: typeof t === 'undefined' ? null : t.empty,
		});
	}

	/**
	 * Get data from clipboard by prompting the user to paste into the dialog.
	 * @returns {Promise<string>}
	 */
	function getTextFromClipboard() {
		// executing a 'paste' command doesn't work either.
		/*return new Promise((resolve, reject) => {
			try {
				const input = document.createElement('input');
				input.tabIndex = -1;
				document.body.appendChild(input);
				input.focus();
				setTimeout(() => {
					console.log(document.execCommand('paste'));
					const { value } = input;
					document.body.removeChild(input);
					resolve(value);
				}, 50);
			} catch (e) {
				reject(e);
			}
		});*/
		return Promise.resolve(prompt(
			'Please paste into this dialog. Your browser doesn\'t allow direct clipboard access.', ''
		));
	}

	function addFromClipboard() {
		let p;
		if ('readText' in navigator.clipboard) {
			p = navigator.clipboard.readText();
		} else {
			// ff only supports this method in extensions, ie/edge don't support it at all
			p = getTextFromClipboard();
		}
		p.then(text => {
			// console.log('Pasted content: ', text);

			if (text) {
				let parsedValues = text.split(",") || [];
				let prevState = props.state || [];
				let newValue = [...prevState, ...parsedValues];
				if ('writeText' in navigator.clipboard) navigator.clipboard.writeText(''); // empty clipboard
				props.formDispatch({
					type: 'change',
					fieldPath: props.fieldPath,
					value: newValue,
				});
			} else {
				showNotice("Oops", 'Clipboard is empty.', 'danger');
			}

		})
			.catch(err => {
				console.error('Failed to read clipboard contents: ', err);
			});

	}

	let staterArray = props.state || [];
	if (!Array.isArray(staterArray)) {
		staterArray = [];
	}

	const displayedElements = Object.values(props.displayIndices).filter(e => e !== -1).length;

	const children = staterArray.map((el, i) => {
		const name = `${props.k}_i${i}`;

		const visualPosition = props.displayIndices[i];
		if (visualPosition === -1) return null;

		return <Row key={i} style={{ order: visualPosition }}>
			<label htmlFor={name}>{visualPosition}:</label>
			<DesiredComp
				parentName={props.k}
				name={name}
				state={el}
				saved={props.saved}
				onChange={(a, b) => onChildInputChange(i, a, b)}
				placeholder={
					props.placeholder === props.k ? name : props.placeholder
					// if placeholder is just the name of the field, append index
					// otherwise use the provided placeholder value
				}
				definition={props.definition}
			/>
			<button type='button' onClick={() => onDeleteButtonClick(i)}>-</button>
			<button type='button' onClick={() => onArrowButtonClick(i, visualPosition - 1)} disabled={visualPosition <= 0}>↑</button>
			<button type='button' onClick={() => onArrowButtonClick(i, visualPosition + 1)} disabled={visualPosition >= displayedElements - 1}>↓</button>
		</Row>;
	});

	return <ArrayEditorContainer>
		{children}
		<Row style={{ order: 99999 }}>
			<button type='button' className="addButton" onClick={addElement}>+</button>
			{props.component !== 'file' && <button
					type='button'
					className="addButton"
					onClick={addFromClipboard}
				>+ from clipboard</button>}
		</Row>
	</ArrayEditorContainer>;
}
